.contactinfo {
  /* border-bottom: 1px solid #b9b7b74f; */
  /* padding: 5px 0 0 15px; */
}
.btnload:hover {
  color: #000000;
}
.para {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #938e8e8a;
  cursor: not-allowed;
}
.card11 {
  top: 14%;
  left: 10%;
}
.paragraph11 {
  font-weight: bold;
  text-align: left;
  padding: 10px 24px 20px 40px;
}
.quantity {
  display: flex;
  justify-content: space-between;
}
.btnshipp {
  font-size: 25px;
  background-color: #e97730;
  /* margin: 15px 10px; */
  padding: 10px 0px;
  border-radius: 10px;
  width: 100%;
}
.quantity14 {
  color: rgb(148, 246, 2);
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
}
/* .ship {
  text-align: justify;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 20px;
} */
.depart {
  text-align: justify;
}
.quali {
  text-align: justify;
}
.deliverymain1 {
  display: flex;
  padding-top: 15px;
  border-bottom: 1px solid #ffffff96;
}
.deliverymain1 .img-card img {
  height: 50px;
  width: 50px;
}
.img-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.delivry {
  /* text-transform: uppercase; */
  font-weight: 700;
  text-align: justify;
}
.paracel h6 {
  text-align: justify;
  font-weight: bold;
}
/* .paracel p {
  text-align: justify;
} */
.fre {
  text-transform: uppercase;
  text-align: justify;
  color: green;
}
.paracel {
  border: 1px solid black;
  padding: 10px;
  border-radius: 8px;
  border-color: #e97730;
  height: 100%;
}
.playfloor1 {
  font-size: x-large;
}
.schiene {
  text-align: justify;
}
.sku {
  text-align: justify;
}
.shipping12 {
  display: flex;
  justify-content: space-around;
}

/* ********************************************************************************************* */
.checkoutDeliveryOption {
  box-shadow: 0px 3px 11px 3px #c6bfbf8a;
  border-radius: 15px;
  padding: 10px 20px;
  margin: 0 0 20px 0;
  background-color: #fff;
}
.Place-order a {
  background-color: #e97730;
  color: #fff;
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1;
  padding: 15px 20px;
  text-align: center;
  border-radius: 0;
  z-index: 9;
  border-radius: 8px;
}
.prodImgForCheckout {
  height: 60px;
}
.prodNaming {
  border-top: 1px solid #dee0e4;
  padding: 10px 0 0 0;
}
.prodNaming:first-child {
  border-top: none;
  padding: none;
}
.inputWithbankinCardsImages {
  background-color: #fff;
  border: 1px solid #8080804d;
  border-radius: 5px;
}
.paymentform label {
  margin: 10px 0 0 0;
}
.container1 {
  display: flex;
  justify-content: space-between;
  background: #d6d6d64a;
  border: 1px solid #b9b8b863;
  padding: 5px;
  box-shadow: 1px 2px 3px #80808069;
  border-radius: 5px;
}
@media (max-width: 1200px) {
  .prodImgForCheckout {
    height: 40px;
  }
}
@media (max-width: 1024px) {
  .prodImgForCheckout {
    height: 40px;
  }
}
@media (max-width: 992px) {
  .prodImgForCheckout {
    height: 60px;
    width: 60px;
  }
  .prodNaming .row .col-lg-3 {
    padding: 0px !important;
  }
}
/* ************************* confirm ******************* */

.confirm {
  border: 1px solid #b9b8b863;
  padding: 5px;
  border-radius: 5px;
}
.inputsForOTP {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.inputsForOTP input {
  width: 35px;
  padding: 5px;
  box-shadow: 1px 1px 3px rgb(171 169 169 / 71%);
  border: 1px solid #d1cfcfd6;
  border-radius: 5px;
  outline: none;
}
.CheckoutAsGuest {
  border: 1px solid #8080807a;
  padding: 10px;
  text-align: center;
  box-shadow: 1px 2px 3px gray;
  border-radius: 5px;
}
.CheckoutAsGuest a {
  color: #e97730;
}

