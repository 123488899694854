.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #e67929 !important;
  color: #fff !important;
}
.nav-link {
  font-weight: 600;
}
.nav-link i {
  padding: 0 15px 0 0;
  font-weight: 600;
}
.full-box {
  border: 1px solid #dbdbd7;
  border-radius: 10px;
  box-shadow: 1px 2px 20px 2px #dbdbd7;
}
/* /********************* addresses **************** */
.addressModal {
  border: 1px solid #dbdbd7;
  border-style: dashed;
  border-width: 2px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  cursor: pointer;
}
.addressModal i {
  font-size: 2rem;
  color: #d6d6d6f7;
}

.viewAddress {
  border: 1px solid #afafae;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
}
.forDefault .defaultMark {
  background: #6ee673;
  width: fit-content;
  padding: 0px 5px;
  border-radius: 20px;
}
.Options a {
  font-size: 0.8rem;
  color: #e67929;
}
.Options {
  bottom: 0;
  padding: 10px 0 0 0;
  color: #e67929;
}
.address-modal {
  background-color: #e97730 !important;
  color: #fff;
}
/* *********************** favorites */
.favoriteHeadings {
  background-color: rgb(233, 233, 233);
  border: 1px solid rgb(190, 188, 188);
  border-radius: 5px 5px 0 0;
  padding: 5px;
}

.inStock {
  text-align: center;
}
.inStock .InStock {
  background-color: #6ee673;
  padding: 2px 12px;
  border-radius: 30px;
  width: fit-content;
}
.ProductContent {
  border-bottom: 1px solid rgb(233, 233, 233);
  border-left: 1px solid rgb(233, 233, 233);
  border-right: 1px solid rgb(233, 233, 233);
}
.inStock .outOfStock {
  background-color: red;
  color: #fff;
  padding: 2px 12px;
  border-radius: 30px;
  width: fit-content;
}
.action a {
  border: 1px solid #b6b3b3;
  margin: 0 0 10px 0;
}
.FavoriteBottom {
  background-color: rgb(233, 233, 233);
  border: 1px solid rgb(190, 188, 188);
  border-radius: 0 0 5px 5px;
}
.myProfilePagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}
.myProfilePagination a {
  background: #bcb8b8;
  border: 1px solid #bab8b8;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
}
@media (max-width: 992px) {
  .myProfilePagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }
  .action {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

/***************************** Order History ****************/

.forIcon {
  background-color: #fff;
  border: 1px solid gray;
  padding: 5px 10px;
}
.inputforOrderHistory {
  /* outline: none;
  border: none; */
  width: 100%;
}
.FilterForShip {
  cursor: pointer !important;
}
.OrderStatusComplete {
  background-color: #56a6593a;
  color: #023b02;
  padding: 2px 8px;
  width: fit-content;
  border-radius: 20px;
}

.OrderStatusIncomplete {
  background-color: #fd9b8e6d;
  color: #500000;
  padding: 2px 8px;
  width: fit-content;
  border-radius: 20px;
}
.viewOrderDetailBtn {
  border: 1px solid rgb(180, 180, 180);
  border-radius: 5px;
  /* cursor: not-allowed !important; */
}
.shipped-pending .shipped {
  background-color: #56a6593a;
  color: #023b02;
  padding: 2px 8px;
  width: fit-content;
  border-radius: 20px;
}
.shipped-pending .pending {
  background-color: #fd9b8e6d;
  color: #500000;
  padding: 2px 8px;
  width: fit-content;
  border-radius: 20px;
}
.historyDetailsBtn {
  border: 1px solid #8080808c;
  border-radius: 0 0 5px 5px;
}
.orderHistoryBuyAgainbtn {
  border: 1px solid #8080808c;
  padding: 2px 10px;
  border-radius: 5px;
  background: #fff;
  color: #5a5959;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .OrderHistoryprice-total {
    float: right !important;
  }
  .orderHistoryRow {
    padding: 0 10px !important;
  }
  .ProductName h6 {
    font-size: 12px;
  }
  .ProductName p {
    font-size: 12px;
  }
  .OrderHistoryprice-total h6 {
    font-size: 12px;
  }
  .OrderHistoryprice-total p {
    font-size: 12px;
    font-weight: 400;
  }
  .OrderStatusComplete {
    padding: 2px 5px;
    font-size: 12px;
  }
  .productImg {
    padding: 2px!important;
  }
  .price h5{
    font-size: 16px;
    padding-top: 10px;
  }
  .action button {
    padding-bottom: 15px;
}

}
.productImg {
  padding: 10px;
}
@media (max-width: 992px) {
  .orderHistorybtn {
    float: left !important;
    margin-top: 10px !important;
  }
}

.pagination .page-link span {
  color: black; /* Ensures text is black */
}
