.popup {
  background-color: #f7f6ed;
  padding: 1.5rem;
  border-radius: 10px;
  width: 75%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-20px);
  opacity: 0;
  animation: slideIn 0.3s forwards;
  position: relative;
  max-height: 80vh; /* Restrict the popup height */
  overflow-y: auto; /* Allow scrolling inside the popup */
  scrollbar-width: none; /* For Firefox - hide scrollbar */
}

/* Hide scrollbar in WebKit browsers (Chrome, Safari) */
.popup::-webkit-scrollbar {
  display: none;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden; /* Prevent overflow scrolling */
}

.quote-header {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0; /* Optional: light border */
  margin-bottom: 20px;
}

.quote-text h2 {
  font-size: 1.45rem;
  font-weight: 600;
  margin-top: 12px;
  color: #333; /* Adjust text color */
}

.quote-text p {
  font-size: 0.875rem;
  color: #666;
  margin: 0;
  line-height: 18px;
}

/* Close button for the popup */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Smooth slide-in animation */
@keyframes slideIn {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-input {
  width: 100%;
}
.quote-form {
  width: 100%;
}
@media (max-width: 600px) {
  .popup {
    width: 95%;
  }
}
