.search-grid {
  display: grid;
  grid-template-columns: 20fr 80fr;
  align-items: center;
}
.search-bar-for-search-option {
  border: 1px solid #80808066;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  background-color: #fff;
  z-index: 100000;
  left: -1px;
  height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
.imageSearchProduct {
  width: 50%;
}
.discription-search {
  margin: 0 0 0 -10px;
}
.search-head {
  font-size: 0.7rem;
  font-weight: 600;
}
.search-number {
  font-size: 0.6rem;
}
@media (max-width: 767.98px) {
  .search-bar-for-search-option {
    width: 65vw;
  }
}
@media (max-width: 575.98px) {
  .search-bar-for-search-option {
    width: 100%;
    /* margin-top: -10px; */
  }
}

@media (max-width: 480px) {
  .search-grid {
    display: grid;
    grid-template-columns: 30fr 70fr;
    align-items: center;
  }
}
