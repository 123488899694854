.top-bottom-border {
  border-top: 1px solid #99999994;
  /* border-bottom: 1px solid #99999994; */
  padding: 10px 0;
}
.top-bottom-border:last-child {
  border-top: 1px solid #99999994;
  border-bottom: 1px solid #99999994;
  padding: 10px 0;
}
.quoteRemove input {
  outline: none;
  border: 1px solid #99999994;
  border-radius: 5px;
}

.quoteProductName .quoteProductsName:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #e67929;
}
@media (max-width: 769px) {
  .quoteProductName p {
    margin: 0 !important;
    line-height: 22px;
  }

  .custom-size {
    font-size: 12px;
  }
  .quoteRemove {
    padding-top: 12px;
  }
  .quotePrice {
    padding-top: 12px;
    float: right;
  }
}
.copy-text{
  cursor: pointer;
}

.quoteImage {
  width: 100%; /* Adjust this to the desired square size if needed */
  padding-top: 100%; /* This keeps the aspect ratio square */
  position: relative; /* Allows absolute positioning for the image inside */
  background-color: hsl(0, 0%, 95%);/* Background color for empty space */
  border-radius: 15px;
  box-shadow: #545454 2px 2px 5px;
}

.quoteImage img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the image scales properly without being cropped */
}

.quoteSummaryImage {
  width: 100%; /* Set width */
  padding-top: 100%; /* Padding top as 100% of the container width makes it square */
  position: relative; /* Use relative positioning for the container */
  overflow: hidden; /* Hide any overflow */
  background-color: hsl(0, 0%, 95%);
}

.quoteSummaryImage img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  object-fit: cover; /* Cover the container without losing aspect ratio */
  object-position: center; /* Center the image within the element */
}


