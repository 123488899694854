/* *{
  text-align: justify;
} */
html, body {
  height: 100%; /* Ensure the height of the root elements spans the viewport */
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
}

.main-content {
  flex: 1; /* Take up the remaining space */
}
.slick-next,
.slick-prev {
  padding: 8px;
  width: 2vw !important;
  height: 2vw !important;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: #e67929 !important;
  z-index: 99 !important;
  top: 40%;
  font-size: 2rem;
}

.slick-next:hover {
  background-color: #e05f03 !important;
  color: #fff;
}
.slick-prev:hover {
  background-color: #e05f03 !important;
  color: #fff;
}

/* ************** scroll bar ********************/
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(165, 165, 164, 0.488) !important;
  border-radius: 20px;
  height: 5px !important;
}

/* ************************************************************* experimental navbar css *******************************/
.sub-menu-gradient {
  position: relative;
  overflow: hidden;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 67%) 16%,
    rgba(204, 202, 200, 0) 27%
  );
  pointer-events: none; /* Allow clicks to go through the overlay to the image */
  z-index: 10;
}
.myColor {
  color: #e67929;
  border: none;
  background: none;
}
.myColor:hover {
  color:#535353;
  border: none;
}

.mybgColor {
  background-color: #e67929;
}

.mybgColor:active {
  background-color: #e67929 !important;
  color: #fff !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .slick-slide {
    justify-content: center;
  }
  .slick-next,
  .slick-prev {
    padding: 8px;
    width: 5vw !important;
    height: 5vw !important;
    cursor: pointer;
    border: none;
    border-radius: 5vw;
    background-color: #e67929;
    z-index: 99 !important;
    top: 40%;
    font-size: 2rem;
  }
  .slick-prev {
    left: -2% !important;
  }
  .slick-next {
    right: -2% !important;
  }
}

/* keep these media queries to be used later */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* General Styling */

.main-menu {
  /* background-color: #fff; */

  width: 100%;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.menu-item {
  position: relative;
  padding: 15px 20px;
}

.menu-item a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}
.menu-item-header a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}
.menu-item:hover a {
  color: #00b894;
}

/* Dropdown Styling */
.dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background: #f7f6ed;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
  width: 100%;
}

.dropdown-column {
  display: flex;
  margin-right: 20px;
  align-items: center;
  /* justify-content: space-between; */
  flex-direction: column;
}

.dropdown-column h4 {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 700;
}

.dropdown-column ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-column li {
  margin-bottom: 0px;
  font-size: 18px;
  cursor: pointer;
}

.dropdown-column li a {
  text-decoration: none;
  color: #555;
}
.list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 1.5%;
  color: black;
}
.list:hover{
  background-color: #eeecec;
  color: #e67929;
  padding: 1.5%;
  border-radius: 5px;
}
.sub-cat{
font-size: 18px;
margin-bottom: 5px;

}
.submenu-text,
.submenu-arrow {
  transition: font-weight 0.3s ease;
}

.list:hover .submenu-text,
.list:hover .submenu-arrow {
  font-weight: bold; /* Makes both text and arrow bold on hover */
  color: #000;
}
.shop-all {
  background-color: #e57929;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.shop-all:hover {
  background-color: #cc6b21;
}
.support-div {
  border: 1px solid #e67929;
  border-radius: 15px;
  margin: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.title-text {
  color: #e67929;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .dropdown {
    flex-direction: column;
  }
  .accordion-body {
   padding-left: 10px;
   padding-right:0px ;
    
  }
  



}
.accordion-collapse.collapse {
  border: none !important; /* Remove border for collapsed sections */
}

.accordion-button {
  border: none !important; /* Remove border from the button */
  outline: none !important; /* Ensure no outline is applied */
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important; /* Remove shadow on active button */
  color: #575757!important;
}
.accordion-item {
  border-bottom: 1px solid #ddd !important; /* Subtle border */
}
.accordion-item:last-child {
  border-bottom: 1px solid #212529bf!important ; 
}
.accordion-body {
  max-height: 200px;
  overflow-y: scroll;
  text-align: left;
  
}
.accordion-button:focus {
  outline: none; /* Remove the blue outline */
  box-shadow: none; /* Remove any additional focus box-shadow */
}
.modal.show {
  background-color: rgba(0, 0, 0, 0.5);
}
.accordion-button:not(.collapsed){
  background-color: transparent;
  color: #575757!important;
}


.checkbox-widget input[type=checkbox] {
  /* transform: scale(1.2); */
  outline: 2;
}

.filter-options {
  line-height: 2.2;
}

.filter-options span {
  font-size: 12px;
  color: #6d6d6d;
  margin: auto 0px;
}

.cursor-pointer {
  cursor: pointer;
}
.orange-text{
  color: #e05f03;
  font-weight: 600;
}