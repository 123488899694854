/* .Login-SignupSection {
  height: 100vh !important;
  position: relative;
} */
.signup-box {
 
  padding: 20px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #80808045;
  box-shadow: 1px 2px 10px 1px #8080806e;
  width: 526px;
}
.paragrapg-new {
  text-align: center;
  margin-top: 20px;
  font-weight: 700;
}

.login-box {

  padding: 20px;
  border-radius: 3px;
  background-color: white;
  border: 1px solid #80808045;
  box-shadow: 1px 2px 10px 1px #8080806e;
  width: 526px;
}

/* h1 {
  text-align: center;
  padding-top: 15px;
}

h4 {
  text-align: center;
} */

form label {
  display: flex;
  margin-top: 20px;
  font-weight: 600;
  /* font-size: 18px; */
}

form input {
  /* width: 100%; */
  padding: 7px;
  border: none;
  border: 1px solid gray;
  border-radius: 6px;
  outline: none;
}

input[type="button"] {
  width: 320px;
  height: 35px;
  margin-top: 20px;
  border: none;
  background-color: #e67929;
  color: white;
  font-size: 18px;
}
/* p {
  text-align: center;
  padding-top: 20px;
  font-size: 15px;
} */
.para-2 {
  text-align: center;
  color: #000;
  font-size: 15px;
  /* margin-top: -10px; */
}
.para-2 a {
  color: #e67929;
}
.forSignup {
  color: #e67929;
  cursor: pointer;
  font-size: 1rem !important;
}
.userSignupbtn {
  margin: 20px 0 0 0;
  background: #e67929;
  color: #fff;
  width: 100%;
  padding: 7px;
}
.userSignupbtn:hover {
  background: #ff8a36;
  color: #fff;
}
.passwords {
  text-align: center;
  margin: 10px 0px;
}
.forgot-password {
  display: block;
  margin-top: 20px 0;
  text-align: center;
  color: black;
  /* font-weight: 600; */
}

.forgetPassForm {
  width: 526px;
  margin: 30px auto;
  padding: 30px;
}

.form-container {
  border: 1px solid #80808045;
  box-shadow: 1px 2px 10px 1px #8080806e;
  width: 100%;
  padding: 30px;
  text-align: center;
  background-color: white;
}

input[type="submit"] {
  width: 100%;
  height: 35px;
  margin-top: 20px;
  border: none;
  background-color: #e67929;
  color: white;
  font-size: 18px;
}

@media (max-width: 1399.98px) {
}
@media (max-width: 1198.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767px) {
}
@media (max-width: 575.98px) {
  .login-box {
    width: 100%;
  }
  .signup-box {
    width: 100%;
  }
}
@media (max-width: 480px) {
  /* .logo-box{
    width: 408px;
  } */
  .signup-box {
    width: 365px;
  }
}
@media (max-width: 376px) {
  .login-box {
    width: 100%;
  }
  .signup-box {
    width: 100%;
  }
}

.LofinSignupSubmit:hover {
  background: #ff8a36;
  color: #fff;
}


.hr-lines:before{
  content:" ";
  display: block;
  height: 1px;
  width: 32%;
  position: absolute;
  top: 50%;
  left: 0;
  background: red;
}

.hr-lines{
  position: relative;
  max-width: 500px;
  text-align: center;
}

.hr-lines:after{
  content:" ";
  height: 1px;
  width: 32%;
  background: red;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}
.password-input-container {
  margin-bottom: 1rem;
}

.password-input-wrapper {
  position: relative;
}

.password-input-wrapper input {
  width: 100%;
  padding-right: 40px; /* Space for the eye icon */
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666; /* Icon color */
  font-size: 1.2rem; /* Icon size */
}

.password-toggle-icon:hover {
  color: #000; /* Icon color on hover */
}