.bi-check-circle-fill::before {
  content: "\f26a";
  font-size: 70px;
  box-shadow: 0px -1px 6px 3px #b1adad;
  border-radius: 50%;
}
.ViewOrder-buttton {
  border: 1px solid #cecaca;
  color: #000 !important;
  padding: 9px 50px;
  border-radius: 10px;
  /* padding-left: 50px; */
  background: #e9e9e9;
}
