.add-labour-items select {
  padding: 3px;
  font-size: 14px;
  border-radius: 20px;
  /* text-align: center; */
}

.removal-disposal {
  background-color: white;
  border-radius: 9px;
  overflow: hidden;
  border: 1px solid #999;
}

.removal-disposal .add-selection-name {
  background-color: #e9e9e9;
  padding: 14px 10px;
  border-right: 1px solid #999;
}

.removal-disposal p {
  /* text-align: left; */
  font-size: 14px;
  margin-bottom: 0px;
}
.removal-disposal .name-removal-disposal p {
  text-align: left;
}
.removal-disposal .row .col-md-4 p {
  text-align: left;
}

.removal-disposal div span {
  padding: 5px 10px;
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  font-size: 14px;
}

.removal-disposal button {
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  padding: 5px;
}

.add-installation-product select {
  padding: 3px;
  font-size: 14px;
  border-radius: 5px;
  outline: none;

  /* text-align: center; */
}

/* .product-disclamer p::selection {
} */
.product-disclamer p {
  font-size: 10px;
  line-height: 0.9;
  color: #b1b1b1;
}

.total-price-cost-estimation-name p,
.total-price-cost-estimation p {
  font-size: 18px;
}

.prev-next-button button {
  /* background-color: #e67929;
  padding: 15px 0px;
  border: none;
  border-radius: 15px;
  margin-right: 10px; */

  background: #e67929;
  padding: 10px 35px;
  border-radius: 5px;
  border: 1px solid #d3d3d0;
}
.red-text {
  position: absolute;
  width: 25%;
  padding: 2px 0 0 10px;
}
@media (max-width: 1199px) {
  
}
@media (max-width: 991.98px) {
  .removal-disposal p {
    font-size: 14px;
  }
  .removal-disposal div span {
    padding: 5px 3px;
  }
  .red-text {
    position: absolute;
    width: 28%;
    padding: 0 50px 0 20px;
  }
}
@media (max-width: 767.98px) {
  .add-labour-items select,
  .removal-disposal .row .col-md-4 p {
    text-align: center;
  }
  .removal-disposal .name-removal-disposal p {
    text-align: center;
  }
  .removal-disposal button {
    border: 1px solid #999;
    margin: 5px 0;
  }
  .total-price-cost-estimation-name p,
  .total-price-cost-estimation p {
    margin-left: 75%;
  }

  .removal-disposal .add-selection-name {
    border-bottom: 1px solid #999;
  }
  
}

@media (max-width: 576px) {
  .step-wizard {
    display: none;
  }
  .red-text {
display: flex;
justify-content: center;
align-items: center;
width: 90%;
padding-left: 0px;
padding-right: 0px;
  }
}
.remove-box {
  width: 400px;
}
.remove-box .yes-remove {
  background-color: #e67929;
  border: 1px solid #e67929;
  border-radius: 7px;
}
.remove-box .yes-cancel {
  background-color: #e6e6e6;
  border: 1px solid #999;
  border-radius: 7px;
}
