
:root {
  --primary-orange: #e67929;
  --background-cream: #f7f6ed;
  --text-gray: #535353;
  --white: #ffffff;
  --black: #000000;
}

.product-detail-input-width {
  border: 1px solid rgba(118, 118, 118, 0.277);
  background-color: #fff;
  border-radius: 5px;
  padding: 0px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
}
.product-detail-input-width span {
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(75, 75, 75, 0.794);
}

.product-detail-input-width input::placeholder {
  font-size: 9px !important;
  display: flex;
  align-items: center;
}

.product-detail-input-room-name span {
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.product-detail-input-room-name input::placeholder {
  font-size: 9px !important;
  display: flex;
  align-items: center;
}
.product-detail-input-room-name {
  border: 1px solid rgba(118, 118, 118, 0.277);
  background-color: #fff;
  border-radius: 5px;
  padding: 0px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  width: 150px;
}
.waste-include {
  display: flex;
  justify-content: space-between;
}

.enter-width {
  padding: 0 0 0 10px;
}
.input-email {
  padding: 0 5px;
}
.add-btn {
  text-align: right;
}
.specification tr td b {
  padding: 0 10px 0 0;
}
@media (min-width: 1400px) {
  .add-btn {
    text-align: left;
  }
}
@media (max-width: 1200px) {
  .product-detail-input-room-name {
    /* border: 1px solid rgba(118, 118, 118, 0.277);
    background-color: #fff;
    border-radius: 5px;
    padding: 0px 4px 0px 0px;
    display: flex;
    justify-content: space-between; */
    width: 100%;
  }
  .add-room-btn {
    padding: 6px 5px;
  }
  .add-btn {
    text-align: center;
  }
}
@media (max-width: 991px) {
  .product-detail-add-room-form {
    padding: 10px !important;
  }
  .product-name {
    padding-top: 20px;
  }

  .enter-width {
    padding: 0 0 0 0px;
  }
  .input-email {
    padding: 0 0px;
  }

  .add-btn {
    text-align: center;
  }
  .check-box-btn {
    padding: 10px 0 0 0;
    text-align: center;
  }
  .form-check .form-check-input {
    float: inherit;
  }
  .form-check{
    display: flex;
  }
  .form-check-label{
    margin-top: 0px;
    margin-left: 5px;
  }
  .input-box {
    margin: 0;
    padding: 5px 10px;
  }
  .box-in-waste {
    display: flex;
    justify-content: center;
  }
  .box-coverage-row {
    padding: 0% !important;
  }
  .total-text {
    margin: 0;
    text-align: center;
  }
  .prize-txt {
    margin: 0;
    text-align: center;
  }
  .coverage-total {
    display: flex;
  }
  .product-detail-input-width {
    margin: 10px 0;
  }
  .product-detail-input-room-name {
    width: 100%;
    border: 1px solid rgba(118, 118, 118, 0.277);
    background-color: #fff;
    border-radius: 5px;
    padding: 0px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
  }
  .input-room-name {
    /* padding-right: calc(var(--bs-gutter-x) * 0.5) !important; */
    /* padding-left: calc(var(--bs-gutter-x) * 0.5) !important; */
    margin: 10px 0;
  }
  .input-box {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .product-detail-add-room-form {
    padding: 10px !important;
  }
  .product-name {
    padding-top: 10px;
  }

  .enter-width {
    padding: 0 0 0 0px;
  }
  .input-email {
    padding: 0 0px;
  }

  .add-btn {
    text-align: center;
  }
  .check-box-btn {
    padding: 10px 0 0 0;
    text-align: center;
  }
  .form-check .form-check-input {
    float: inherit;
  }
  .input-box {
    margin: 0;
    padding: 5px 10px;
  }
  .box-in-waste {
    display: flex;
    justify-content: center;
  }
  .box-coverage-row {
    padding: 0% !important;
  }
  .total-text {
    margin: 0;
    text-align: center;
  }
  .prize-txt {
    margin: 0;
    text-align: center;
  }
  .coverage-total {
    display: flex;
  }
  .product-detail-input-width {
    margin: 10px 0;
  }
  .product-detail-input-room-name {
    width: 100%;
    border: 1px solid rgba(118, 118, 118, 0.277);
    background-color: #fff;
    border-radius: 5px;
    padding: 0px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
  }
  .input-room-name {
    /* padding-right: calc(var(--bs-gutter-x) * 0.5) !important; */
    /* padding-left: calc(var(--bs-gutter-x) * 0.5) !important; */
    margin: 10px 0;
  }
  /* .input-box {
    width: 40%;
  } */
}
.form-control:focus {
  background-color: var(--bs-body-bg);
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

/********************************************* New UI ***********/
.productDetailQuantity {
  display: flex;
  border: 1px solid gray;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background: #fff;
}
.productDetailQuantity input {
  outline: none;
  border: none;
}
.addtoCalculate {
  width: fit-content;
}
.calculatedArea {
  border: 1px solid rgba(178, 175, 175, 0.36);
  padding: 10px;
}
.calculatedArea table tbody tr {
  border-bottom: 1px solid rgba(178, 175, 175, 0.36);
}
.calculatedArea .total {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 10px 0 0 0;
}

.confirm:hover {
  background-color: #e67929;
  color: #fff;
}

.product-detail-card {
  border-radius: 20px !important;
  position: relative;
}

@media (max-width: 600px) {
 
  .product-detail-card{
    height: 386px;
  }
}

.favorite-heart-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background-color: transparent; /* Transparent background outside the icon */
  border: 1px solid white; /* White border around the button */
  cursor: pointer; /* Make it clear that it's clickable */
  width: 40px; /* Set width of the button */
  height: 40px; /* Set height of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Makes the button round */
}

.favorite-heart-btn i {
  font-size: 24px; /* Adjust font size as needed */
  color: #ff0000; /* Icon color red when filled */
  background-color: white; /* White background for the icon */
  border-radius: 50%; /* Round shape for the icon's background */
  width: 100%; /* Use full width of the button */
  height: 100%; /* Use full height of the button */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Specific style when heart is not filled */
.favorite-heart-btn .bi-suit-heart {
  color: #ff0000; /* Red icon color when not filled */
}

@media (max-width: 512px) {
.first-text{
  display: none;
}
.second-text{
  display: none;
}
}
.add-quote-summery-btn.disabled-btn {
  background-color: grey;
  cursor: not-allowed;
  opacity: 0.6;
}

.add-quote-summery-btn.disabled-btn:hover {
  cursor: not-allowed;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.preview-media .modal-content {
  position: relative;
  background: white;
  padding: 3px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: normal;
}

.preview-media .modal-media {
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.preview-media .close-button {
  position: absolute;
  top: -20px;
  left: -20px;
  font-size: 24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  border: none;
  cursor: pointer;
  color: black;
}

.selected-thumbnail {
  border: 1px solid var(--primary-orange);
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {

  .modal-content {
    max-width: 90%;
    min-height: 30vh;
  }
  .modal-media {
    width: 100%;
    height: 400px;
    border-radius: 4px;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
}