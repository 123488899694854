/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap");

:root {
  --primary-orange: #e67929;
  --background-cream: #f7f6ed;
  --text-gray: #535353;
  --white: #ffffff;
  --black: #000000;
}


* {
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* text-align: center; */
}

body {
  background-color: #f7f6ed !important;
}

.bg-fld {
  background-color: #e67929;
}

.breadcrumb ul {
  display: flex;
  gap: 5px;
}
@media (max-width: 600px) {
  .breadcrumb ul {
    padding: 0px;
    font-size: 12px;
    line-height: 18px;
  }
  .breadcrumb ul li a {
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long links */
    display: inline-block;
    max-width: 100px; /* Adjust width based on your design */
  }
}

.breadcrumb ul li a {
  color: #535353;
}

.heading h1 {
  color: #535353;
}
@media (max-width: 600px) {
  .heading {
    padding: 10px;
  }
}


.heading p {
  color: #535353;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  color: #535353;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #535353;
  margin-bottom: 15px;
  font-family: "Nunito", sans-serif;
}

.z-max {
  z-index: 999999;
}

.mt-60 {
  margin-top: 60px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-75 {
  margin-bottom: 75px;
}
.mb-90 {
  margin-bottom: 90px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pt-50 {
  padding-top: 50px;
}
.ptb-150 {
  padding: 150px 0;
}

.ptb-50 {
  padding: 50px 0;
}
.ptb-30 {
  padding: 30px 0;
}
.ptb-20 {
  padding: 20px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.fs-10 {
  font-size: 10px;
}
.rounded-10 {
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .pb-65 {
    padding-bottom: 35px;
  }
}

.pb-70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .pb-70 {
    padding-bottom: 40px;
  }
}

.pb-75 {
  padding-bottom: 75px;
}

@media only screen and (max-width: 767px) {
  .pb-75 {
    padding-bottom: 45px;
  }
}

.pb-85 {
  padding-bottom: 85px;
}

@media only screen and (max-width: 767px) {
  .pb-85 {
    padding-bottom: 55px;
  }
}

.pb-95 {
  padding-bottom: 95px;
}

@media only screen and (max-width: 767px) {
  .pb-95 {
    padding-bottom: 65px;
  }
}

.pb-90 {
  padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .pb-90 {
    padding-bottom: 60px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 70px;
  }
}

.pt-80 {
  padding-top: 80px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-90 {
  padding-top: 90px;
}

@media only screen and (max-width: 767px) {
  .pt-90 {
    padding-top: 60px;
  }
}

.pt-95 {
  padding-top: 95px;
}

@media only screen and (max-width: 767px) {
  .pt-95 {
    padding-top: 65px;
  }
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 70px;
  }
}

.pt-145 {
  padding-top: 145px;
}

.default-button {
  color: #fff;
  background: #e67929;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
}

/* ******************** header *********************** */

.top-header {
  background-color: #e67929;
  padding-top: 3px; /* Reduced padding-top */
  padding-bottom: 3px; /* Reduced padding-bottom */
}

.phone {
  float: right;
}

.top-header p {
  margin-top: 0; /* Reduce or remove top margin */
  margin-bottom: 0; /* Reduce or remove bottom margin */
  line-height: 1; /* Reduce line height */
}

.top-header p a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  
}

.top-header p i {
  padding-right: 6px;
}

@media screen and (max-width: 780px) {
  .top-header {
    display: none;
  }
}

/* ***************************** Second navbar ********************** */

.v-center {
  align-items: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* header */
.header {
  display: block;
  width: 100%;
  position: relative;
  z-index: 99;
  padding: 15px;
}

.header .item-left {
  /* flex:0 0 17%; */
  flex: 0 0 12%;
}

.header .logo a {
  font-size: 30px;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
}

.header .item-center {
  /* flex:0 0 66%; */
  flex: 0 0 50%;
}

.header .item-right {
  /* flex:0 0 17%; */
  flex: 0 0 35%;
  display: flex;
  justify-content: space-around;
  justify-content: flex-end;
}

.header .item-right a {
  text-decoration: none;
  font-size: 1rem;
  color: #555555;
  display: inline-block;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.header .item-right a span {
  background: #e67929;
  height: 1.2rem;
  padding: 8px;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.header .item-right a span:hover {
  color: #000;
}

.header .item-right a i {
  font-size: 1.3rem;
  padding-right: 5px;
}

.header .item-right a p {
  color: #535353;
}

.header-item.item-center .searching {
  border: 1px solid gray;
  padding: 8px 10px;
  background: #fff;
  border-radius: 30px;
}




.header-item.item-center .searching i {
  color: #535353;
}

.header-item.item-center .searching input {
  outline: none;
  border: none;
  padding-left: 10px;
  width: 90%;
}

.header-item.item-right a {
  display: flex;
  font-size: 0.7rem;
}
.header-item.item-right {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.item-right a,
p {
  color: #535353;
}
.item-right a i {
  font-size: 1.2rem;
  /* padding-right: 5px; */
}
.item-right a span {
  background: #e67929;
  height: 1rem;
  color: #fff;
  padding: 5px;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .header-item.item-left {
    display: flex;
    /* justify-content: center; */
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .header-item.item-center {
    margin: 10px 0;
  }
  .header-item.item-right {
    margin: 10px 0;
    display: flex;
    /* justify-content: center; */
   
  }
}
@media (min-width: 481px) and (max-width: 767.98px) {
  .header-item.item-left {
    display: flex;
    /* justify-content: center; */
    margin: 10px;
  }
  .header-item.item-center {
    margin: 10px 0;
  }
  .header-item.item-right {
    margin: 10px 0;
    display: flex;
    /* justify-content: center; */
    /* margin-top: -115px; */
  }
}
@media (min-width: 767.98px) and (max-width: 1024px) {
  .header-item.item-left {
    display: flex;
    /* justify-content: center; */
    margin: 10px;
  }
  .header-item.item-center {
    margin: 10px 0;
  }
  .header-item.item-right {
    margin: 10px 0;
    display: flex;
    /* justify-content: center; */
  }
}
@media (max-width: 767.98px) {
  .v-center {
    display: flex;

  }
}

/* ***************************** main navbar ********************** */

:before,
:after {
  box-sizing: border-box;
}

/* .container{
	max-width: 1200px;
	margin:auto;
} */

.v-center {
  align-items: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* header */
.header {
  display: block;
  width: 100%;
  position: relative;
  z-index: 99;
  padding: 15px;
}

.header .item-left {
  /* flex:0 0 17%; */
  flex: 0 0 12%;
}

.header .logo a {
  font-size: 30px;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
}



.header .item-center {
  /* flex:0 0 66%; */
  flex: 0 0 50%;
}

.header .item-right {
  /* flex:0 0 17%; */
  flex: 0 0 35%;
  display: flex;
  justify-content: space-around;
  justify-content: flex-end;
}

.header .item-right a {
  text-decoration: none;
  font-size: 1rem;
  color: #555555;
  display: inline-block;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.header .item-right a span {
  background: #e67929;
  height: 1.2rem;
  padding: 8px;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.header .item-right a span:hover {
  color: #000;
}

.header .item-right a i {
  font-size: 1.3rem;
  padding-right: 5px;
}

.header .item-right a p {
  color: #535353;
}

.header .menu > ul > li {
  display: inline-block;
  /* line-height: 50px; */
  /* margin-left: 25px; */
  /* margin-left: 60px; */
  padding: 5px 25px;
  border: 2px solid transparent;
}

.header .menu > ul > li > a {
  font-size: 18px;
  font-weight: 400;
  color: #525050;
  position: relative;
  text-transform: capitalize;
  transition: color 0.3s ease;
}

/* /.header .menu > ul > li:hover {/
  /* background: #c7c4c43b; */
  /* padding: 1px 20px; */
  /* border-radius: 25px;
  color: gray; */

.header .menu > ul > li.menu-item:hover,
.header .menu > ul > li.menu-item:active{
  position: relative;
  border: 2px solid #525050;
  background: none;
}
.header .menu > ul > li.menu-item::after{
  position: absolute;
  top: 12px;
  left: -2px;
  right: auto;
  content: "";
  background: #f7f6ed;
  width: 2px;
  height: 15px;
}
.header .menu > ul > li.menu-item::before{
  position: absolute;
  top: 12px;
  right: -2px;
  left: auto;
  content: "";
  background: #f7f6ed;
  width: 2px;
  height: 15px;
}







.header .menu > ul > li .sub-menu {
  position: absolute;
  z-index: 500;
  background-color: #ffffff;
  /* box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3);  */
  padding: 20px 30px;
  /* transition: all 0.5s ease; */
  transition: all 0.5s ease, visibility 0s linear 0.3s, opacity 0.5s ease;
  box-shadow: 1px 7px 5px 0px #a4a3a3;


  margin-top: 0px;
  /* opacity: 0; */
  visibility: hidden;
  display: none; 
}






.menu-main .menu-item-has-children:first-child {
  margin: 0 0 0 -25px;
}



@media (min-width: 992px) {
  .header .menu > ul > li.menu-item-has-children:hover .sub-menu {
    /* margin-top: 0; */
    opacity: 1;
    /* transition-delay: 0s; */
  }
}

.header .menu > ul > li .sub-menu > ul > li {
  line-height: 1;
}

.header .menu > ul > li .sub-menu > ul > li > a {
  display: inline-block;
  padding: 10px 0;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
}

.header .menu > ul > li .single-column-menu {
  min-width: 280px;
  max-width: 350px;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li {
  line-height: 1;
  display: block;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
  padding: 3 px 0;
  display: inline-block;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
}

.header .menu > ul > li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}





.header .menu > ul > li .sub-menu.mega-menu-column-4 {
  /* max-width: 1100px; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item {
  /* flex:0 0 25%; */
  flex: 0 0 12%;
  padding: 0 15px;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .title {
  font-size: 16px;
  color: #e67929;
  font-weight: 500;
  line-height: 1;
  padding: 10px 0;
}

.header
  .menu
  > ul
  > li
  .sub-menu.mega-menu-column-4
  > .list-item.text-center
  .title {
  text-align: center;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img {
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  margin-top: 10px;
  border-radius: 15px;
  position: relative;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item a {
  position: relative;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item h4 {
  /* text-align: left !important; */
  position: absolute;
  /* top: 40px !important; */
  bottom: -5px;
  z-index: 10;
  padding-left: 5px !important;
  /* bottom: -29px; */
  font-size: 0.8rem !important;
  color: #fff !important;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item h4 i {
  /* right: -47px; */
  position: absolute;
  left: 115px;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .subset li {
  text-align: left !important;
  line-height: 1;
}

/* Header Sub Category Font Setting */
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .subset li a {
  font-size: 0.9rem !important;
  color: #000000 !important;
  /* Ellipsis for Long Sub-category Names */

}

.header
  .menu
  > ul
  > li
  .sub-menu.mega-menu-column-4
  > .list-item
  .subset
  li
  a:hover {
  background-color: #8a8a8a1d !important;
  transform: scale(1.1);
  /* padding: 5px !important; */
  color: #e67929 !important;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 {
  background-color: #f7f6ed;
  padding-left: 100px;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover {
  color: #e67929;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item.support-circle {
  border: 1px solid #e67929;
  /* height: 6rem; */
  /* padding-bottom: 20px; */
  border-radius: 15px;
  margin: 10px;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item.support-circle:hover {
  background-color: #fff;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item.support-circle .small {
  width: 40%;
}

.header-item.item-center .searching {
  border: 1px solid gray;
  /* width: 100%; */
  /* height: 1.8rem; */
  padding: 8px 10px;
  background: #fff;
  border-radius: 30px;
}

.header-item.item-center .searching i {
  color: #535353;
}

.header-item.item-center .searching input {
  outline: none;
  border: none;
  padding-left: 10px;
  width: 90%;
}

/* banner section */
.banner-section {
  /* background-image: url('../img/banner.jpg'); */
  background-size: cover;
  background-position: center;
  height: 700px;
  width: 100%;
  display: block;
}

.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

/*responsive*/
@media (max-width: 991px) {
  .header {
    display: none !important;
  }

  .header .item-center {
    order: 3;
    flex: 0 0 100%;
  }

  .header .item-left,
  .header .item-right {
    flex: 0 0 auto;
  }

  .v-center {
    justify-content: space-between;
  }

  .header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .header .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: #333333;
    width: 24px;
    position: relative;
  }

  .header .mobile-menu-trigger span:before,
  .header .mobile-menu-trigger span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333333;
  }

  .header .mobile-menu-trigger span:before {
    top: -6px;
  }

  .header .mobile-menu-trigger span:after {
    top: 6px;
  }

  .header .item-right {
    align-items: center;
  }

  .header .menu {
    position: fixed;
    width: 320px;
    background-color: #ffffff;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(-100%);
    transition: all 0.5s ease;
    z-index: 1099;
  }

  .header .menu.active {
    transform: translate(0%);
  }

  .header .menu > ul > li {
    line-height: 1;
    margin: 0;
    display: block;
  }

  .header .menu > ul > li > a {
    line-height: 50px;
    height: 50px;
    padding: 0 50px 0 15px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header .menu > ul > li > a i {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 50px;
    transform: rotate(-90deg);
  }

  .header .menu .mobile-menu-head {
    display: flex;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: -webkit-sticky;
    position: sticky;
    background-color: #ffffff;
    top: 0;
  }

  .header .menu .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 16px;
    display: none;
  }

  .header .menu .mobile-menu-head.active .go-back {
    display: block;
  }

  .header .menu .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }

  .header .menu .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 25px;
  }

  .header .menu .menu-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .header .menu > ul > li .sub-menu.mega-menu,
  .header .menu > ul > li .sub-menu {
    /* visibility: visible;
    opacity: 1; */
    visibility: hidden; /* Start as hidden */
    /* opacity: 0;  */
    
    position: absolute;
    box-shadow: none;
    margin: 0;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 65px;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    pointer-events: none
  }

  .header .menu > ul > li .sub-menu.active {
    visibility: visible; /* Make visible */
    opacity: 1; /* Fully opaque */

    display: block;
  }

  .sub-menu.non-interactable {
    pointer-events: none; /* Disable pointer events */
  }


  @keyframes slideLeft {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes slideRight {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }

    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img {
    margin-top: 0;
  }

  .header
    .menu
    > ul
    > li
    .sub-menu.mega-menu-column-4
    > .list-item.text-center
    .title {
    margin-bottom: 20px;
  }

  .header
    .menu
    > ul
    > li
    .sub-menu.mega-menu-column-4
    > .list-item.text-center:last-child
    .title {
    margin-bottom: 0px;
  }

  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item {
    flex: 0 0 100%;
    padding: 0px;
  }

  .header .menu > ul > li .sub-menu > ul > li > a,
  .header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
    display: block;
  }

  .header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
    margin-bottom: 15px;
  }

  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}
.bg-color1 {
  background-color: #e67929;
}
.bg-color {
  background-color: #f7f6ed;
}
.bg-color-for-canvas {
  background-color: #f7f6ed;
}
.bg-color-for-canvas1 {
  background-color: hsla(28, 5%, 48%, 0.851);
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #e67929 !important;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #525050 !important;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (max-width: 1400px) {
  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item h4 i {
    /* right: -47px; */
    position: absolute;
    left: 105px;
  }
}
@media (min-width: 1200px) and (max-width: 1365px) {
  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item h4 i {
    /* right: -47px; */
    position: absolute;
    left: 80px;
  }
  /* .header .menu > ul > li .sub-menu.mega-menu-column-4 {
    background-color: #f7f6ed;
    padding-left: 0px;
  } */
}
@media (max-width: 1199px) and (min-width: 991px) {
  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item h4 i {
    position: absolute;
    left: 70px;
  }
  .header .menu > ul > li .sub-menu.mega-menu-column-4 {
    background-color: #f7f6ed;
    padding-left: 30px;
  }
}

/************************** Responsive navbar  ************************/

@media (max-width: 990.98px) {
  .responsive-nav-for-sm-screen {
    display: block !important;
  }
  .header {
    display: none !important;
  }
}

@media (min-width: 990.98px) {
  .responsive-nav-for-sm-screen {
    display: none !important;
  }
  .header {
    display: block !important;
  }
}

.custom-offcanvas {
  max-width: 80%; /* Adjust this value as needed */
}

/* *************************** responsive brand slider ***************** */

/* @media (max-width: 991px) {
  .brands-for-large-screen {
    display: none !important;
  }
  .brands-for-small-screen {
    display: block !important;
  }
}
@media (min-width: 990px) {
  .brands-for-large-screen {
    display: block !important;
  }
  .brands-for-small-screen {
    display: none !important;
  }
} */

/************************ Main image slider ************************/

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 5px !important;
  height: 5px !important;
  border-radius: 50% !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 9px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 0 !important;
  margin-bottom: 1rem;
  margin-left: 0 !important;
  display: none;
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 1 !important;
  display: none;
  /* transition: opacity .15s ease; */
}

.carousel-indicators .active {
  opacity: 1 !important;
  background-color: #fff;
}

/* .as-bg {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding-top: 4px;
} */

.as-bg i {
  color: #000;
  font-size: 2rem;
}

.image-caption {
  position: absolute;
  /* right: 15%; */
  bottom: 5.2rem !important;
  left: 11% !important;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: inherit;
  z-index: 10;
}

.image-caption h1 {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  padding-bottom: 10px;
}

.image-caption h5 {
  font-weight: 400;
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  padding-bottom: 25px;
}

.image-caption a {
  background: #e67929;
  padding: 10px 15px;
  border-radius: 6px;
  color: #fff;
}

.carousel-item {
  position: relative;
}

.carousel-item.gradient::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: rgb(0, 0, 0); */
  /* background: linear-gradient(
    90deg,
    rgba(21, 39, 54, 1) 0%,
    rgba(247, 247, 247, 0.21621148459383754) 72%,
    rgba(21, 39, 54, 1) 100%
  ); */
  /* background: linear-gradient(rgba(0, 0, 0, 0.447), rgba(0, 0, 0, 0.447)); */
  /* background: radial-gradient(closest-side, #3f87a6 0%, #ebf8e1 0%, #f69d3c); */
}

/* the setting for the dradient for New Collection slider */
.card-slide-img.gradient::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;

  /* background: linear-gradient(
    356deg,
    rgba(91, 86, 86, 1) 14%,
    rgba(144, 141, 138, 0.0845588235294118) 100%
  ); */
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.768032212885154) 5%,
    rgba(255, 253, 250, 0.017331932773109293) 20%
  );
}

/* @media (max-width: 991px) {
  /* .carousel .carousel-inner .carousel-item img {
    /* height: 210px !important; */
  


@media (max-width: 991px) {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 1rem !important;
    width: 1rem !important;
  }

  .as-bg i {
    font-size: 1rem;
  }

  .image-caption {
    position: absolute;
    /* right: 15%; */
    bottom: 1.25rem !important;
    left: 11% !important;
    padding-top: 1.25rem;
    padding-bottom: 0rem!important;
    color: #fff;
    text-align: inherit;
  }
  .carousel-indicators{
    margin-bottom: 0rem !important;
    display:none;
  }
  .image-caption h1 {
    font-size: 1.3rem;
    font-weight: 600;
    padding-bottom: 10px;
  }

  .image-caption h5 {
    padding-bottom: 10px;
    font-weight: 100;
    font-size: 1rem !important;
  }

  .image-caption a {
    padding: 4px 10px;
    font-size: 1rem;
  }
  .image-caption {
    margin-bottom: 0px !important;
  }
}

/* ***********************  Stats *************************** */

/* .stats {
  padding: 50px 0;
} */

.stat {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.stat i {
  color: #e67929;
  font-size: 2.5rem;
}

.stat .fa-truck {
  padding: 10px 0;
}
.stat .fa-truck::before {
  padding: 10px 0;
}

.stat h3 {
  color: #e67929;
  margin: 0;
  font-weight: 500;
  font-size: 1.4rem;
}

.stat p {
  color: #535353;
}

@media (max-width: 1198.98px) {
  .stat h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 991px) {
  .stat {
    margin: 10px;
    text-align: center;
  }
  .stat h3 {
    font-size: 1.1rem;
  }
}

@media (max-width: 767px) {
  .stat h3 {
    font-size: 1rem;
  }
}

/* ************************** card slider (new collection) ***********************/
/* ************************** Extra Css ************************* */
.bi-arrow-right-short::before {
  content: "\f135";
  background: #E67929 !important;
  color: #fff !important;
  border-radius: 40px !important;
}

.bi-arrow-left-short::before {
  content: "\f12c";
  background: #E67929 !important;
  color: #fff !important;
  border-radius: 40px !important;
}
.slick-arrow {
  color: #fff !important;
}

.prev i {
  position: absolute;
  left: -40px;
  bottom: 110px;
  font-size: 40px;
  z-index: 1;
}

.prev .previous-btn::before {
  background-color: #e67929 !important;
  color: #fff !important;
}

.next .previous-btn::before {
  background-color: #e67929 !important;
  color: #fff !important;
}

.next i {
  position: absolute;
  right: -40px;
  bottom: 110px;
  font-size: 40px;
  z-index: 1;
}

.brand-logo {
  position: relative;
  flex: 0 0 auto;
  z-index: 5;
  top: 50%;
  /* top: 0; */
  left: 50%;
  transform: translate(-50%, 0%);
  width: 150px;
  /* Adjust the width of each logo container */
  height: 150px;
  /*Adjust the height of each logo container*/
  /* overflow: hidden; */
  cursor: pointer;
  margin: 5px 0;
}
.brand-logos {
  position: relative;
  flex: 0 0 auto;
  z-index: 5;
  /* top: 50%; */
  /* top: 0; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  width: 150px;
  /* Adjust the width of each logo container */
  height: 150px;
  /*Adjust the height of each logo container*/
  /* overflow: hidden; */
  cursor: pointer;
}

.layer1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1;
  border-radius: 15%;
  transition: transform 0.3s ease;
  box-shadow: 0px 3px 3px #908c8c;
}

.layer2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layer2 img {
  max-width: 80%;
  max-height: 100%;
}

.brand-logo:hover .layer1 {
  transform: scale(1.02);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .brand-logo {
    width: 100px;
    height: 100px;
  }
}

.card-slide .card-slide-img {
  position: relative;
  /* width: 80%; */
  border: 1px solid rgba(143, 142, 142, 0);
  border-radius: 35px;
}

.card-slide .card-slide-img a {
  position: relative;
}

.card-slide .card-slide-img a img {
  position: relative;
  border-radius: 17px;
  height: 255px;
  width: 260px;
  object-fit: fill;
}

.card-slide .card-slide-img .product-badge span {
  position: absolute;
  background: #ffeb00;
  padding: 5px 14px;
  top: -2px;
  right: 0;
  overflow: hidden;
  border-radius: 0px 19px 0;
  cursor: pointer;
}
.card-slide .card-slide-img .product-badge span a {
  color: black;
  cursor: pointer;
}

.card-slide {
  display: flex !important;
  justify-content: center;
}

.card-slide .card-slide-img .new-badge span {
  position: absolute;
  bottom: 10px;
  /* right: 115px; */
  left: 0;
  text-align: initial;
  padding: 0 0 0 10px;
  color: #fff;
  z-index: 10;
}

.card-slide .card-slide-img .new-badge span h5 {
  color: #fff;
}

.card-slide-prev i {
  left: -35px;
  position: absolute;
}

.card-slide-next i {
  right: -35px;
  position: absolute;
}

.card-slide-prev i::before {
  padding: 5px;
  font-size: 2rem;
  background-color: #e97730 !important;
  color: #fff !important;
}

.card-slide-next i::before {
  padding: 5px;
  font-size: 2rem;
  background-color: #e97730 !important;
  color: #fff !important;
}

@media (max-width: 991px) {
  .card-slide .card-slide-img .product-badge span {
    position: absolute;
    background: #ffeb00;
    padding: 5px 14px;
    top: -2px;
    right: 0;
    overflow: hidden;
    border-radius: 0px 19px 0;
  }

  .card-slide-prev i {
    left: 0px;
    position: absolute;
  }

  .card-slide-next i {
    right: 0;
    position: absolute;
  }
}

/* **************************** brand slider ********************* */
.brand-button {
  color: #e97730;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #e97730;
}

.product-imag img {
  margin: 10px;
}
/* .slick-track {
  width: 5832px;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) !important;
} */
.slick-slide {
  display: flex !important;
  justify-content: center !important;
}
.layer {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layer img {
  max-width: 60%;
  max-height: 100%;
}

/* ****************************** Flooring Products *********************** */

.flooring-products {
  background-color: #f0f0ea;
  position: relative;
}
.brand-flooring-products {
  position: relative;
}
.brand-flooring-products h1 {
  color: #e67929;
}

.flooring-products .container .row {
  position: relative;
}

.appearence {
  position: relative;
}

.flooring-products h1 {
  color: #e67929;
}

.product-image {
  position: relative;
  padding: 20px 0;
  display: flex !important;
  justify-content: center !important;
}

.product-image a {
  position: relative;
}

.product-image a img {
  position: relative;
  border-radius: 15px;
  opacity: 1;
}

.product-image a .product-image-after::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: rgb(0, 0, 0);
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(255, 255, 255, 0.1)
  ); */
  border-radius: 15px;
}

.product-image a p {
  position: absolute;
  /* top: 50px; */
  bottom: -7px;
  padding: 0 0 0 10px;
  color: #fff;
  line-height: 18px;
}

@media (max-width: 991px) {
  .product-image a p {
    position: absolute; 
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    padding: 0 8px;
  }

  .product-image {
    position: relative;
    text-align: center;
    opacity: 1;
    padding: 12px 0;
  }
}

/* ************************** installation section ********************** */

.installation {
  position: relative;
}

.installation .container .row {
  position: relative;
}

.install-box {
  position: relative;
}

.install-box img {
  position: relative;
  border-radius: 30px;
}

.install-box .caption {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .install-box .caption {
    padding: 10px 0px;
    font-size: 12px;
  }
}

.caption p {
  color: white;
}

#view {
  position: absolute;
  display: flex;
  bottom: 10px;
  right: 10px;
}

#view h3 {
  color: #fff;
  font-weight: 100;
  font-size: 1.3rem;
}

@media (max-width: 991px) {
  .install-box {
    padding: 10px 0;
  }

  .caption-home {
    margin: 10px 0;
  }

  .caption p {
    color: #fff;
  }

  #view {
    position: absolute;
    display: flex;
    bottom: 0px;
  }

  .install-box img {
    position: relative;
    border-radius: 20px;
  }

  #view h3 {
    color: #fff;
    font-weight: 100;
    font-size: 0.9rem;
  }

  #view i {
    border-radius: 111px;
    padding: 0 3px;
    color: #000;
  }
}

/* *************************** Footer **************************** */

.footer {
  border-top: 1.5px solid #c3c2c2;
  border-bottom: 1.5px solid #c3c2c2;
  background-color: #e6e6e6;
  padding: 6px 0 5px 0;
}

.logos {
  padding-bottom: 4px;
}

.footer .insta {
  display: flex;
  align-items: top;
}

.insta p {
  color: #535353;
}

.footer .insta i {
  font-size: 1rem;
}

.footer .insta p {
  line-height: 1;
  padding: 7px 0 0 5px;
}

.footer .insta p a {
  color: #535353;
}
.inner-row {
  padding-top: 20px;

}

/* ********** */

.time {
  font-size: 0.8rem;
  /* padding-bottom: 10px; */
}

.time a {
  color: #535353;
}

.address p {
  color: #535353;
}

@media (max-width: 576px) {
  .foot-logo {
    text-align: center;
  }
  .footer .insta {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inner-row {
    text-align: center;
  }
  .list {
    text-align: center;
  }
}

.copyright {
  background-color: #d4d4d4;
}
/* **************************************** nav apply **************************************/
.header-item.item-right a {
  display: flex;
  font-size: 0.7rem;
  /* gap: 4px; */
}

/*-------- 18. About us style ---------*/
.about-img {
  margin: 0 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-img {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-img {
    text-align: center;
    margin: 30px 0px 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-img {
    text-align: center;
    margin: 30px 0px 0;
  }
}

.about-img img {
  width: 100%;
  /* -webkit-transition: all .4s ease 0s; */
  /* -o-transition: all .4s ease 0s;
  transition: all .4s ease 0s;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); */
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-img img {
    max-width: 100%;
    width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .about-img img {
    max-width: 100%;
    width: inherit;
  }
}

/* .about-img:hover img {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
} */

.about-content h2 {
  font-size: 30px;
  color: #e97730;
  font-family: "Great Vibes", cursive;
  margin: 0;
}

.about-content h1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  margin: 12px 0 28px;
}

@media only screen and (max-width: 767px) {
  .about-content h1 {
    font-size: 32px;
    margin: 10px 0 18px;
  }
}

.about-content p {
  color: #5e5e5e;
  font-size: 15px;
  font-weight: 300;
  margin: 0 0 26px;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .about-content p {
    font-size: 14px;
    margin: 0 0 16px;
  }
}

.about-content p.mrg-inc {
  margin: 0 0 52px;
}

@media only screen and (max-width: 767px) {
  .about-content p.mrg-inc {
    margin: 0 0 23px;
  }
}

/* *************************** Contact ***************** */

.contact .address-first {
  height: 100%;
  margin: 20px 0;
  padding: 20px 50px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid rgb(144, 143, 143);
}
@media (max-width: 768.98px) {
  .contact .address-first {
    height: fit-content;
  }
}

.contact .address-first h3 {
  color: #535353;
}

.contact .address-first .maps iframe {
  border: 1px solid rgb(144, 143, 143);
}

.contact .address-first .contact-info {
  padding-top: 20px 30px;
}

.contact .address-first .contact-info .address1 {
  display: flex;
}

.contact .address-first .contact-info .address1 i {
  /* font-size: 1.3rem; */
  color: #e67929;
  padding: 0 20px 0 0;
}

.contact .address-first .contact-info .address1 p {
  color: #535353;
  /* font-size: 1.3rem; */
}

/* Removed empty ruleset */

.contact .address-first .contact-info .address1 p span {
  color: #f81d27;
}

/* ************************************** side bar ********************* */

#leftmenu > details > summary {
  cursor: pointer;
  margin: 6px;
  padding: 8px;
  background: #dfefff;
}

#leftmenu > details > summary:hover {
  background: #dfefff;
}

#leftmenu > details > summary::-webkit-details-marker {
  display: none;
}

/* ************************************** cost-estimation *************************** */

.new-estimation p {
  text-align: center;
  font-size: 1rem;
  margin-top: 15px;
}

.cost-estimation .cost-img img {
  border-radius: x;
  /* justify-content: space-between; */
}

.cost-form input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #cccaca;
  margin-right: 20px;
  width: 100%;
}
.cost-form .place-cost::placeholder {
  font-size: 0.8rem;
}
.cost-form .button {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #c0bebe;
}

@media (max-width: 991px) {
  .cost-form input {
    padding: 10px 35px;
    border-radius: 15px;
    border: 1px solid #cccaca;
    margin-right: 20px;
  }
}

/* *********************************** new quote summary *********************** */
.billing-info-wrap h3 {
  font-weight: 600;
  color: #212121;
  margin: 0 0 30px;
  font-size: 24px;
  line-height: 16px;
}

.billing-info-wrap .billing-info input {
  /* background: transparent none repeat scroll 0 0; */
  background-color: #fff;
  border: 1px solid #ebebeb;
  color: #474747;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  outline: 0;
  height: 45px;
  border-radius: 10px;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 10px;
}

.billing-info-wrap .billing-info .checkout-account label {
  color: #474747;
  font-weight: 400;
  margin: 0 0 0 12px;
}

.billing-info-wrap .billing-info .checkout-account input {
  border: 1px solid #ebebeb;
  display: inline-block;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-top: 7px;
  cursor: pointer;
}

.billing-info-wrap .billing-select select {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #474747;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  outline: 0;
  height: 45px;
}

.billing-info-wrap .checkout-account-toggle input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #474747;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px;
  margin: 0 0 20px;
  width: 100%;
  height: 45px;
  outline: 0;
}

.billing-info-wrap .checkout-account-toggle button.checkout-btn {
  background-color: #ff7004;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 0;
  z-index: 9;
}

.billing-info-wrap .checkout-account-toggle button.checkout-btn:hover {
  background-color: #212121;
}

.billing-info-wrap .additional-info-wrap {
  margin: 30px 0;
}

.billing-info-wrap .additional-info-wrap h4 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  margin: 0 0 10px;
}

.billing-info-wrap .additional-info-wrap textarea {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #474747;
  font-size: 14px;
  height: 138px;
  padding: 17px 20px;
  width: 100%;
  outline: 0;
}

/* .billing-info-wrap label {
  margin-bottom: 10px;
} */

.your-order-area h3 {
  font-weight: 600;
  color: #212121;
  margin: 0 0 30px;
  font-size: 24px;
  line-height: 16px;
}

.your-order-area .your-order-wrap {
  padding: 38px 45px 44px;
  background: #ebebeb;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: Raleway, sans-serif;
}

.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-top
  ul
  li {
  font-size: 16px;
  font-weight: 600;
  list-style: outside none none;
  color: #212121;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-middle {
  border-bottom: 1px solid #dee0e4;
  /* border-top: 1px solid #dee0e4; */
  /* margin: 29px 0; */
  padding: 10px 0 10px;
}

.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-middle
  li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* margin: 0 0 10px; */
  font-size: 0.8rem;
}

.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-bottom
  ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: Raleway, sans-serif;
}

.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-bottom
  ul
  li.your-order-shipping {
  font-size: 16px;
  color: #212121;
  font-weight: 600;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total {
  border-bottom: 1px solid #dee0e4;
  border-top: 1px solid #dee0e4;
  margin: 18px 0 33px;
  padding: 17px 0 19px;
}

.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-total
  ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-total
  ul
  li {
  font-weight: 600;
  color: #e97730;
  font-size: 16px;
  list-style: outside none none;
  font-family: Raleway, sans-serif;
}

.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-total
  ul
  li.order-total {
  font-weight: 600;
  color: #212121;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap {
    padding: 38px 30px 44px;
    margin-top: 15px;
  }

}

.your-order-area .payment-accordion {
  margin: 0 0 16px;
}

.your-order-area .payment-accordion:last-child {
  margin: 0 0 0;
}

.your-order-area .payment-accordion h4 a {
  color: #212121;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.your-order-area .payment-accordion .panel-body {
  padding: 5px 0 0 0;
}

.your-order-area .payment-accordion .panel-body p {
  padding: 0 0 0 10px;
  font-size: 14px;
  color: #474747;
  line-height: 24px;
}

.your-order-area .Place-order {
  margin-top: 25px;
}

.your-order-area .Place-order a {
  background-color: #e97730;
  color: #fff;
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1;
  padding: 13px;
  text-align: center;
  /* text-transform: uppercase; */
  border-radius: 0;
  z-index: 9;
  border-radius: 5px;
}

.your-order-area .Place-order a:hover {
  background-color: #212121;
}

.your-order-area .your-order-wrap {
  padding: 38px 45px 10px;
  background: #ffffff;
  box-shadow: 0px 3px 11px 3px #c6bfbf8a;
  border-radius: 16px;
}

.billing-info sup {
  color: rgb(232, 39, 71);
  font-size: 0.6rem;
}

.checkout-account span {
  color: #e97730;
}

.checkout-account p {
  font-size: 1rem;
}

.checkout-account a {
  color: #e97730;
}

.social-icons {
  /* height: 50px; */
  margin-top: 25px;
  background-color: #fdfdfd;
  border-radius: 10px;
  border: 1px solid lightgrey;
  padding: 5px;
}

.social-icons .icon-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 991px) {
  .social-icons .icon-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;

    overflow-y: scroll;
  }
  .prod-img-list {
    overflow-y: scroll;
  }
}

/* .icon-list li i {
  color: #e97730;
  margin: 10px;
  font-size: 25px;
} */

/* *********************************** Quote Final ********************** */
.installtion-buttton {
  background: #e67929;
  padding: 10px 35px;
  border-radius: 5px;
  border: 1px solid #d3d3d0;
}

@media (max-width: 600px) {
  .installtion-buttton {
    padding: 10px 5px;
    font-size: 12px;
  }
  .easy-services.mt-4.d-flex.justify-content-center span {
    padding-top: 10px;
}



}

.installtion-buttton a {
  color: #fff;
}

.term-condition {
  cursor: pointer;
}

/* ***************************************** faq ****************** */
/* .product-wrap {
  overflow: hidden;
  position: relative;
}

.product-wrap .product-img {
  overflow: hidden;
  position: relative;
}

.product-wrap .product-img a {
  display: block;
}

.product-wrap .product-img a img {
  width: 100%;
  -webkit-transition: all .4s ease 0s;
  -o-transition: all .4s ease 0s;
  transition: all .4s ease 0s;
}

.product-wrap .product-img .product-badge {
  position: absolute;
  z-index: 5;
}

.product-wrap .product-img .product-badge.badge-top {
  top: 11px;
}

.product-wrap .product-img .product-badge.badge-right {
  right: 11px;
}

.product-wrap .product-img .product-badge.badge-pink {
  color: #fd1748;
}

.product-wrap .product-img .product-badge span {
  font-size: 16px;
  font-weight: 500;
}

.product-wrap .product-img .product-badge-2 {
  position: absolute;
  top: 25px;
  right: 30px;
  -webkit-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.product-wrap .product-img .product-badge-2 span {
  font-size: 16px;
  font-weight: 500;
  color: #fd1748;
}

.product-wrap .product-img .product-action-3-wrap {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0.7);
      -ms-transform: translateY(-50%) scale(0.7);
          transform: translateY(-50%) scale(0.7);
  -webkit-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-img .product-action-3-wrap a {
  display: inline-block;
  font-weight: 500;
  color: #202020;
  border-bottom: 1px solid #9d9998;
  text-transform: uppercase;
  line-height: 1;
}

.product-wrap .product-img .product-action-3-wrap a:hover {
  color: #e97730;
  border-bottom: 1px solid #e97730;
}

.product-wrap .product-img .product-action-wrap {
  display: inline-block;
  left: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1 {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
  font-size: 23px;
  height: 45px;
  margin: 0 3px;
  text-align: center;
  width: 45px;
  border: none;
  border-radius: 6px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1 i {
  line-height: 45px;
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1:first-child {
  -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
          transform: translateY(-20px);
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1:last-child {
  -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px);
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1:hover {
  color: #ffffff;
  background-color: #e97730;
}

.product-wrap .product-img .product-action-2-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .4s ease 0s;
  -o-transition: all .4s ease 0s;
  transition: all .4s ease 0s;
}

.product-wrap .product-img .product-action-2-wrap .product-action-btn-2 {
  border: none;
  color: #fff;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
  background-color: #000000;
  -webkit-transition: all .4s ease 0s;
  -o-transition: all .4s ease 0s;
  transition: all .4s ease 0s;
  width: 100%;
  padding: 9px 10px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.product-wrap .product-img .product-action-2-wrap .product-action-btn-2 i {
  font-size: 22px;
  margin-right: 5px;
}

.product-wrap .product-img .product-action-2-wrap:hover .product-action-btn-2 {
  background-color: #e97730;
}

.product-wrap .product-content h3 {
  font-weight: 500;
  margin: 0 0 10px;
  font-size: 16px;
}

.product-wrap .product-content h3 a {
  color: #000000;
}

.product-wrap .product-content h3 a:hover {
  color: #e97730;
}

.product-wrap .product-content.product-content-position {
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 2;
  -webkit-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap.product-overly:before {
  position: absolute;
  content: "";
  left: 40px;
  right: 40px;
  top: 40px;
  bottom: 40px;
  background-color: rgba(245, 245, 245, 0.88);
  -webkit-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.product-wrap:hover .product-img a img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.product-wrap:hover .product-img .product-badge-2 {
  top: 15px;
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-img .product-action-3-wrap {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-50%) scale(1);
      -ms-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1);
}

.product-wrap:hover .product-action-wrap {
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-action-wrap .product-action-btn-1:first-child {
  -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
          transform: translateY(0px);
}

.product-wrap:hover .product-action-wrap .product-action-btn-1:last-child {
  -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
          transform: translateY(0px);
}

.product-wrap:hover .product-action-2-wrap {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover.product-overly:before {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-content.product-content-position {
  bottom: 20px;
  left: 25px;
  opacity: 1;
  visibility: visible;
} */

/* brand code */

/* .product-imag .hover-effect {
  padding: 65px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.4s ease;
  z-index: 2;
}
.product-imag .hover-effect:hover {
  scale: 1.2;
} */

/* .image-container {
  width: 1px;
  padding: 5px;
} */
/* .product-imag .image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100px;
  padding: 5px;
  z-index: 5;
} */

.container .row .back-option p {
  color: #e67929;
}

.preview-input-field {
  display: flex;
  gap: 10px;
}

.previev-input {
  border-radius: 3px;
  border: 2px solid #cac9c6;
  padding: 5px;
  font-size: 14px;
  width: 300px;
}

.preview-button {
  border-radius: 5px;
  border: 2px solid #cac9c6;
  padding: 3px 8px;
  font-size: 14px;
  background-color: #f7f6ed;
}

.quote-summery-number {
  color: #525050;
}

.quote-summery-heading h2 {
  font-size: 30px;
  color: #525050;
}

.modal-button {
  background: #e97730 !important;
  border: none !important;
  padding: 10px 50px !important;
}
.cart-button{
  background: #e97730 !important;
  border: none !important;
  padding: 10px 50px !important;
  color:white;
  border-radius: 5px;
  font-size: 14px;
}

.cart-button:hover{
  background: #f8812c!important;
  border: none !important;
  padding: 10px 50px !important;
  color:white;
  border-radius: 5px;
}


/* **************************** quote summary (shri) *********************/

.back-option a {
  color: #e67929;
}

.preview-input-field {
  display: flex;
  gap: 10px;
}

.previev-input {
  border-radius: 3px;
  border: 2px solid #cac9c6;
  padding: 5px;
  font-size: 14px;
  max-width: 300px;
}
.preview-button {
  border-radius: 5px;
  border: 2px solid #cac9c6;
  padding: 3px 8px;
  font-size: 14px;
  background-color: #f7f6ed;
}

.quote-summery-number {
  color: #525050;
}

.quote-summery-heading h2 {
  font-size: 30px;
  color: #525050;
}

@media (max-width: 768px) {
  .quote-summery-heading h2 {
    font-size: 24px;
  }
}

.quote-summery-products-container {
  display: grid;
  grid-template-columns: 20fr 80fr;
  justify-content: space-between;
  padding: 10px 0;

  /* gap: 10px; */
  border: 1px solid #525050;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
  width: 100%;
}

.quote-summery-product-image-remove-product {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.quote-summery-product-image {
  max-width: 161px;
  max-height: 161px;
}

.quote-summery-product-image img {
  width: 100%;
  border-radius: 15px;
}

.quote-summery-product-remove-product {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.quote-summery-product-remove-product .bi-trash3 {
  font-size: 0.7rem;
}
.quote-summery-product-remove-product a {
  font-size: 0.7rem;
}

.quote-summery-product-remove-product,
.quote-summery-product-remove-product p {
  color: #e67929;
  margin: 0;
  width: 100%;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
}

.quote-summery-product-details-container {
  padding: 10px;
  font-size: 14px;
}

.quote-summery-product-name {
  width: 100%;
}
.quote-summery-product-name h4 {
  font-size: 14px;
  color: #e67929;
}

.quote-summery-product-code h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.quote-summery-product-code-checkbox {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.quote-summery-product-checkbox {
  display: flex;
  align-items: center;
}

.quote-summery-product-code-checkbox input {
  margin-right: 10px;
}

.quote-summery-product-dimensions-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
}

.quote-summery-product-dimensions-box,
.quote-summery-product-dimensions-width,
.quote-summery-product-dimensions-roomname,
.quote-summery-product-dimensions-length {
  width: 110px;
  margin: 5px 0;
}
.quote-summery-product-dimensions-box input::placeholder,
.quote-summery-product-dimensions-width input::placeholder,
.quote-summery-product-dimensions-roomname input::placeholder,
.quote-summery-product-dimensions-length input::placeholder {
  font-size: 11px;
}

.quote-summery-product-dimensions-box input,
.quote-summery-product-dimensions-width input,
.quote-summery-product-dimensions-roomname input,
.quote-summery-product-dimensions-length input {
  width: 100%;
  padding: 7px 7px;
  border: 1px solid #909090;
  border-radius: 7px;
  color: #535353;
  outline: none;
}
.quote-summery-product-dimensions-width input,
.quote-summery-product-dimensions-length input {
  border: none;
}
.quote-summery-product-dimensions-width,
.quote-summery-product-dimensions-length {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid gray;
  border-radius: 7px;
  padding: 0 5px;
}
.quote-summery-product-dimensions-width label,
.quote-summery-product-dimensions-length label {
  color: #909090;
}

.quote-summery-product-dimensions-container button {
  padding: 10px 12px;
  border: 1px solid #525050;
  border-radius: 7px;
  font-size: 10px;
  background-color: #dedede8c;
  font-weight: 700;
}

.quote-summery-product-details-container hr {
  border: 1px solid #d8d8d8;
  /* color: #d8d8d8; */
}

.quote-summery-product-added-rooms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.added-room-discription {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.added-room-discription p,
.added-room-discription h6 {
  margin-bottom: 0;
}

.added-rooms-buttons button {
  border: none;
  background-color: transparent;
  padding-right: 10px;
  color: #e67929;
}

.quote-summery-product-total-coverage {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.quote-summery-product-total-coverage h5 {
  font-size: 16px;
}

.quote-summery-product-total-prices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.quote-summery-product-total-prices h5,
.quote-summery-product-total-prices p {
  margin-bottom: 0;
  font-size: 16px;
}

.product-total-prices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.quote-summery-final-total-price {
  display: flex;
  justify-content: end;
  padding: 10px 0;
}
@media (min-width: 1400px) {
  .quote-summery-product-dimensions-box,
  .quote-summery-product-dimensions-width,
  .quote-summery-product-dimensions-roomname,
  .quote-summery-product-dimensions-length {
    width: 135px;
    margin: 5px 0;
  }
}
@media (max-width: 376px) {
  .previev-input {
    width: 260px;
  }
}

@media (max-width: 1200px) {

  .quote-summery-product-dimensions-container {
    display: grid;
    grid-template-columns: 25fr 25fr 25fr 25fr;
  }
  .added-room-discription p,
  .added-room-discription h6 {
    font-size: 12px;
  }
}
@media (max-width: 991px) {
  /* .float-end {
    float: left;
  } */
  .back-option a {
    margin: 20px 0px;
  }
}
@media (max-width: 768px) {
  /* p {
    font-size: 10px;
  } */
  .quote-summery-product-dimensions-container {
    grid-template-columns: 33fr 33fr 33fr;
  }
  .added-room-discription {
    gap: 7px;
  }
  .added-room-discription p,
  .added-room-discription h6 {
    font-size: 10px;
  }
  .added-rooms-buttons {
    display: flex;
    justify-content: end;
    flex-direction: column;
  }
  .added-rooms-buttons button {
    padding-right: 0;
  }
  .quote-summery-product-total-coverage h5,
  .quote-summery-product-total-prices h5,
  .quote-summery-product-total-prices p {
    font-size: 14px;
  }
}

@media (max-width: 480.98px) {
  .quote-summery-products-container {
    grid-template-columns: 1fr;
  }
  .quote-summery-product-image-remove-product {
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
  }

  .added-rooms-buttons {
    display: flex;
    justify-content: end;
    flex-direction: column;
  }
}

@media (max-width: 376px) {
  .quote-summery-product-dimensions-container {
    grid-template-columns: 50fr 50fr;
  }
  .added-room-discription {
    gap: 5px;
  }
  .added-rooms-buttons button {
    padding-right: 0;
  }
}

/* **************************** Cost estimation (kajal) ***********************/

.cost-estimation-tab {
  display: flex;
  justify-content: space-around;
  padding: 30px 10px 0 10px;
}

.center-area {
  background: #fff;
  border-radius: 10px;
  margin: 20px;
}

.room-area-btn {
  /* background-color: #e6e1e1; */
  border-radius: 30px;
  color: black;
  padding: 5px 30px;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 0.7rem;
}
.room-area-btn:active {
  background-color: #e6e6e6;
  border-radius: 30px;
  color: #000;
}
.cost-installation-room-area {
  border: none;
}
.cost-installation-room-area::placeholder {
  font-size: 0.7rem !important;
}
.add-room-area {
  border: 1px solid #80808054;
  display: flex;
  align-items: center;
  padding: 0 2px;
  border-radius: 5px;
}
.room-area-sq-ft {
  font-size: 0.6rem;
}

.room-area-btn:hover {
  background-color: #e6e6e6;
  border-radius: 30px;
  color: #000;
  cursor: pointer;
  /* font-size: 14px;
  padding: 5px 40px;
  text-align: center;
  display: flex;
  align-items: center; */
}

.wid-len {
  padding: 10px;
}

.into-sign {
  padding-top: 45px;
  padding-left: 30px;
}

.room-width {
  padding-top: 40px;
}

.room-width ::placeholder {
  font-size: 14px;
}

.room-length {
  padding-top: 40px;
}

.room-length ::placeholder {
  font-size: 14px;
}

.step-wizard {
  /* height: 2.7px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.step-wizard-list {
  list-style-type: none;
  border-radius: 10px;
  display: flex;
  padding: 20px 10px;
  position: relative;
  z-index: 10;
}

.step-wizard-item {
  display: flex;
  padding: 0px 20px;
  flex-basis: 0;
  max-width: 100%;
  flex-direction: column;
  text-align: center;
  min-width: 170px;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}

.step-wizard-item + .step-wizard-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background-color: #e97730;
  width: 250%;
  height: 4px;
  transform: translate(-50%);
  z-index: -10;
}

.progress-count1 {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 10 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}

.progress-count1:after {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #e97730;
  position: absolute;
  border-radius: 50%;
  left: -150%;
  top: 50%;
  transform: translate(-50%, -40%);
  z-index: -10;
}
.progress-label1 {
  font-size: 13px;
  font-weight: 700;
  /* margin-top: 10px; */
  margin-left: -200px;
}

.one {
  height: 26px;
  width: 26px;
  /* background-color: #e97730; */
  border-radius: 50%;
  transform: translate(-310%, -110%);
  /* content: '1'; */
  color: #fff;
  font-weight: 700;
  padding-top: 3px;
}

.progress-count2 {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 10 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}

.progress-count2:after {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #e97730;
  position: absolute;
  border-radius: 50%;
  left: -150%;
  top: 50%;
  transform: translate(1200%, -40%);
  z-index: -10;
}
.progress-label2 {
  font-size: 13px;
  font-weight: 600;
}
.two {
  height: 26px;
  width: 26px;
  /* background-color: #ebb28f; */
  border-radius: 50%;
  transform: translate(170%, -110%);
  /* content: '2'; */
  color: #fff;
  font-weight: 700;
  padding-top: 3px;
}
.progress-count3 {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 10 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}

.progress-count3:after {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #e97730;
  position: absolute;
  border-radius: 50%;
  left: -150%;
  top: 50%;
  transform: translate(2500%, -40%);
  z-index: -10;
}
.progress-label3 {
  font-size: 13px;
  font-weight: 600;
  transform: translate(110%, -10%);
}

.three {
  height: 26px;
  width: 26px;
  /* background-color: #ebb28f; */
  border-radius: 50%;
  transform: translate(670%, -110%);
  /* content: '2'; */
  color: #fff;
  font-weight: 700;
  padding-top: 3px;
}

/* ******************************* Product details getting errors in this css *************************** */

.easyzoom-style {
  position: relative;
}

.easyzoom-style .easyzoom {
  display: block;
}

.easyzoom-style .easyzoom > a {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.easyzoom-style .easyzoom > a img {
  width: 100%;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.easyzoom-style a.easyzoom-pop-up {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background-color: #fff;
  color: #000;
  font-size: 23px;
  text-align: center;
  border-radius: 100%;
  z-index: 992;
  -webkit-box-shadow: 0 0 19px 2.9px rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 19px 2.9px rgba(0, 0, 0, 0.02);
}

.easyzoom-style a.easyzoom-pop-up i {
  line-height: 50px;
}

.easyzoom-style a.easyzoom-pop-up:hover {
  background-color: #e97730;
  color: #ffffff;
}

.card-body {
  object-fit: cover;
  padding: 0;
}
.card-bodyy {
  /* object-fit: cover; */
  padding: 0;
}

.product-detail-card {
  border-radius: 20px !important;
  height: 420px;
  position: relative;
}

.product-detail-img {
  width: 100%;
  border-radius: 20px;
  /* opacity: 0.8; */
  height: 420px;
}

@media (max-width: 600px) {
  .product-detail-img {
    height: 386px;
  }

  .product-detail-card{
    height: 386px;
  }
}

.product-detail-product-badge span {
  position: absolute;
  z-index: 5;
  background: #f3d028;
  padding: 7px 20px;
  border-radius: 20px 0;
  top: 0;
}

.new-badge-img {
  margin-bottom: 350px;
}

.product-details-discount-badge span {
  padding: 3px 15px;
  background: #24bf0b;
  position: absolute;
  right: 80px;
  top: 20px;
  margin-bottom: 350px;
  border-radius: 20px;
  color: #fff;
}
@media (max-width: 990.98px) {
  .product-details-discount-badge span {
    right: 20px;
    top: 10px;
  }
}

.next-link-btn-color {
  color: #e67929;
}

.next-link-btn-color-two {
  color:  #535353;
}

.next-link-btn-color-two:hover {
  color: #e67929;
}

.next-link-btn-color:hover {
  color: #535353;
}

.Price-discount {
  font-size: 22px;
}

.discount {
  color: red;
}

.older-price {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  margin-left: 8px;
}

.input-1 {
  /* width: 90%; */
  border: none;
  font-size: 0.7rem;
  outline: none;
  /* border-radius: 20px; */
}

.input-2 {
  /* width: 90%; */
  /* margin-left: -25px; */
  border: none;
  outline: none;

  font-size: 0.7rem;
}

.input-3 {
  width: 50%;
  /* margin-left: -45px; */
  border: none;
  outline: none;

  font-size: 0.7rem;
}

.add-room-btn {
  /* height: 38px; */
  /* margin-left: -25px; */
  padding: 6px;
  font-size: 0.7rem;
}

/* .check-box-btn {
  padding-top: 50px;
} */

/* .input-box {
  margin-top: 40%;
  width: 40%;
  height: 54%;
  border: none;
  margin-left: -15%;
} */

.total-text {
  /* margin-left: 70px; */
  text-align: right;
}

.add-quote-summery-btn {
  background-color: #e67929;
  border: none;
  width: 100%;
  /* height: 50px; */
  padding: 10px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.add-quote-summery-btn:hover{
  background-color: #f8812c;
}
.add-quote-summery_clearbg-btn {
  background-color: white;
  border: 1px solid #e67929;
  width: 100%;
  /* height: 50px; */
  padding: 10px;
  border-radius: 5px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-name {
  font-size: 17px;
}

.total-cov-txt {
  font-size: 13.6px;
}

.prize-txt {
  /* font-size: 27px;
  margin-left: 20px; */
  text-align: right;
}

.product-pic {
  height: 80px;
  width: 82px;
  border-radius: 10px;
}

.product-pic-list {
  height: 100px;
  width: 100px;
}

.social-icons {
  /* height: 50px; */
  width: fit-content;
  margin: 25px 0;
  background-color: #fdfdfd;
  border-radius: 10px;
  border: 1px solid lightgrey;
}

.icon-list {
  display: flex;
  flex-wrap: wrap;
}

.icon-list li i {
  color: #e97730;
  margin: 10px;
  font-size: 1rem;
}

.desc-spec-doc {
  color: black;
  /* width: 38.5%; */
  margin-top: 25px;
  /* margin-left: 47px; */
  background-color: #fdfdfd;
  border-radius: 10px;
  border: 1px solid lightgrey;
}

.descripton.active {
  border: none;
  border-bottom: 3px solid #e67929;
  background-color: #fff;
}

.txt1 {
  list-style-type: disc;
  font-size: 15px;
}

.tab-pane {
  font-size: 15px;
  margin: 20px;
}
.tab-pane00 {
  font-size: 15px;
}
@media (max-width: 767.98px) {
  .tab-pane {
    margin: 10px 10px;
  }
}

.descripton {
  border: none;
  background-color: #fff;
}

.link-row {
  justify-content: space-between;
  margin-top: 20px;
}

.adding-extra-room {
  display: flex;
  justify-content: space-around;
}
.naming-equals {
  font-size: 10px;
}

.naming-equals span {
  font-size: 10px;
  padding: 0 0 0 35px;
}
.remove-room {
  cursor: pointer;
  border-bottom: 1px solid #e67929 !important;
  color: #e67929;
  font-size: 10px;
  border: none;
  background-color: transparent;
}
@media (min-width: 1400px) {
  .add-room-btn {
    /* height: 38px; */
    /* margin-left: -25px; */
    padding: 6px 28px;
    font-size: 0.7rem;
  }
}

/* @media screen and (max-width: 375px) {
  .input-email {
    margin-top: 5px;
  }

  .product-name {
    width: 100%;
    padding-top: 30px;
  }

  .input-1 {
    width: 105%;
    padding-top: 2%;
  }

  .input-2 {
    margin-left: 0px;
    width: 105%;
    padding-top: 2%;
  }

  .input-room-name {
    padding-left: 55px;
    padding-top: 10px;
  }

  .add-btn {
    padding-left: 40px;
    padding-top: 10px;
  }

  .check-box-btn {
    padding: 0;
    padding-top: 20px;
  }

  .input-box {
    margin: 10px;
    margin-left: -0px;
  }

  .box-coverage {
    margin-top: -50px;
  }

  .cover-txt {
    margin-top: -20px;
  }

  .tot-cov {
    margin-top: -90px;
  }

  .total {
    margin-top: 70px;
    margin-left: -70px;
  }

  .prize-txt {
    margin-left: 0px;
  }

  .covtxt {
    margin-top: -65px;
  }

  .social-icons {
    height: 100px;
    width: 95%;
    margin: 10px;
    padding: 0px;
  }

  .desc-spec-doc {
    height: 58vh;
    width: 97%;
    margin: 0px;
    padding: 0px;
    margin-left: 5px;
  }

  .link-row {
    font-size: 15px;
  }

  .product-img-list {
    flex-wrap: wrap;
  }
} */

@media (max-width: 991px) {
  .desc-spec-doc {
    /* height: 350px; */
    color: black;
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    background-color: #fdfdfd;
    border-radius: 10px;
    border: 1px solid lightgrey;
  }
}

/* ******************************************* */
/* ******************************************************************************************************************** */

/* #form {
  text-align: center;
  position: relative;
  margin-top: 20px
}

#form fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative
}

.finish {
  text-align: center
}

#form fieldset:not(:first-of-type) {
  display: none
}

#form .previous-step,
.next-step {
  width: 100px;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right
}

.form,
.previous-step {
  background: #616161;
}

.form,
.next-step {
  background: #e97730;
}

#form .previous-step:hover,
#form .previous-step:focus {
  background-color: #000000
}

#form .next-step:hover,
#form .next-step:focus {
  background-color: #e97730;
}

.text {
  color: #e97730;
  font-weight: normal
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey
}

#progressbar .active {
  color: #e97730;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400
}

#progressbar #step1:before {
  content: "1"
}

#progressbar #step2:before {
  content: "2"
}

#progressbar #step3:before {
  content: "3"
}

#progressbar li:before {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  font-size: 15px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
  margin-left: 10px;
  
}

#progressbar li:before{
  width: 30px;
  height: 30px;
  line-height: 30px;
}

#progressbar li:after {
  content: '';
  width: 715px;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 45px;
  z-index: -3
} 

#progressbar li.active:before,
#progressbar li.active:after {
  background: #e97730;
} */

.underline-text {
  text-decoration: underline;
}

/* / Existing styles for larger screens / */
.testimonial-text {
  font-size: 1.5em; /* or whatever your default size is */
}
.caption {
  position: relative;
}

/* / Media query for mobile devices / */
@media (max-width: 768px) {
  .testimonial-text {
    font-size: 1.2em; /* smaller font size for mobile */
  }
}

/* *************** new css for stats *********** */
.stat-container {
  height: 160px; /* Fixed height */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Keeps child elements within the container */
  position: relative; /* Needed for pseudo-element positioning */
}

.stat-content {
  display: flex; /* Enables Flexbox */
  justify-content: space-between; /* Space out items */
  align-items: center; /* Vertically center items */
  height: 100%; /* Full height of the container */
  position: relative; /* Needed for positioning the pseudo-element */
  z-index: 1; /* Ensure it's above the pseudo-element */
}

.stat-content::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 85%; /* Height of the light gray background */
  background-color: #E7E4E1;
  border-radius: 10px; /* Apply border-radius to match the container */
  z-index: -1; /* Places the background behind the content */
}

.stat-title {
  font-family: Verdana, sans-serif; /* Use the default font */
  font-weight: bold; /* Make the text bold */
  margin-top: 20px; /* Add margin to the top, adjust as needed */
  margin-left: 15px; /* Space from left edge */
}

.stat-image {
  height: 100%; /* Full height of the container */
  max-height: 100%; /* Ensure it doesn't exceed container's height */
  margin-right: 15px; /* Space from right edge */
  padding-top: 10px;
}


.stat-content .stat-description {
  position: absolute;
  bottom: 15px; /* Adjust as needed */
  left: 15px; /* Adjust as needed */
}

.sub-cat-link {
  font-size: 1.1rem; /* Increase font size */
  white-space: nowrap; /* Keep text on one line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.product-image-container {
  /* position: relative; */
  transition: border 0.3s ease;
  padding: 5px;
  border-radius: 10px;
}

.product-image-container img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: fill;
}

.product-image-container:hover {
  border: 1px solid var(--primary-orange);
  cursor: help;
}

.product-image-container::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 10;
}

.product-image-container:hover::before {
  opacity: 1;
  cursor: help;
}

.product-image-container::after {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.specs-table {
  border-spacing: 1em;
}

@media (max-width: 500px) {
  .add-quote-summery-btn{
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .mobile-padding {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .custom-padding {
    padding-right: 3px;
    padding-left: 8px;
  }
}
@media (max-width:1399px){
  .header .menu > ul > li {
    display: inline-block;
    /* line-height: 50px; */
    /* margin-left: 25px; */
    /* margin-left: 60px; */
    padding: 1px 12px;
  }
}
@media (max-width:1199px){
  .header .menu > ul > li {
    display: inline-block;
    /* line-height: 50px; */
    /* margin-left: 25px; */
    /* margin-left: 60px; */
    padding: 1px 7px;
  }
}

#product-info-tabs .nav-link {
  cursor: pointer;
  border-bottom: 2px solid transparent; /* Default (no underline) */
}

#product-info-tabs .nav-link.active {
  border-bottom: 2px solid var(--primary-orange) /* Underline color for active tab */
}

.cursor-pointer {
  cursor: pointer;
}

.breadcrumb-item a {
  color: var(--primary-orange) !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 0.5rem;
}
i.bi.bi-send {
  padding-left: 10px;
}

.form-check-input:checked{

  background-color: #e97730;
  border: 1px solid #e97730;  
}

.accordion-item:not(:first-of-type){
  border-top: 1px solid #212529bf;
}



.cart .quantity button {

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e6;
  outline: none;
  color: black;
  background-color: transparent;
  padding-bottom: 2px;
}

.cart .quantity .cart-btn:hover {
  background-color: #f3f1d8 !important;
}

.cart .text-orange {
  color: var(--primary-orange) ;
}