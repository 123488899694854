.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #e97730 !important;
}
.userwithName p {
  line-height: 0px !important ;
}

/* *********************************** Navbar css for user ************* */

.userwithName p {
  height: fit-content;
  padding: 2px 3px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  border: 1px solid #adadad;
}

.sub-cat-link {
  font-size: 1.1rem; /* Increase font size */
  white-space: nowrap; /* Keep text on one line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

/* Add margin-bottom to the navbar on screens smaller than 768px */
@media (max-width: 767.98px) {
  .mobile-navbar-spacing {
    margin-bottom: 0.5rem; /* or 8px if you prefer */
  }
}
@media only screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
  .for-mobile-sticky{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; 
    background-color: #F7F6ED;
    margin-bottom: 100px;
    box-shadow: 1px 1px 8px 0px #a4a3a3;
  }
  .content{
    margin-bottom: 110px;
  }
  .mobile-banner {
    position: relative;
    margin-bottom: 1rem;
  }

  .mobile-banner-caption {
    background: rgba(0,0,0,0.5);
    color: white;
  }

  .mobile-banner-caption h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .mobile-banner-caption h6 {
    font-size: 1rem;
    margin-bottom: 0;
  }

  /* #mobileCarousel {
    margin-top: 60px; /* Adjust based on your navbar height */


  #mobileCarousel .carousel-item {
    border-radius: 8px;
    overflow: hidden;
  }

  #mobileCarousel .carousel-indicators {
    bottom: 0;
  }

  #mobileCarousel .mobile-banner-caption {
    background: linear-gradient(transparent, rgba(0,0,0,0.7));
    padding: 20px 15px;
    bottom: 0;
  }

  #mobileCarousel .mobile-banner-caption h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }

  #mobileCarousel .mobile-banner-caption p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 769px) {
  .hide-on-large-screen {
    display: none !important;
  }
}
