.shop-topbar-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper {
    display: block;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: inherit;
  }
}

.shop-topbar-wrapper .shop-topbar-left span {
  color: #6d6d6d;
  font-weight: 500;
  /* font-style: italic; */
}

.shop-topbar-wrapper .shop-topbar-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .shop-topbar-right {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper .shop-topbar-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 0px;
  }
  
}

.shop-topbar-wrapper .shop-topbar-right .shop-sorting-area {
  /* margin-right: 10px; */
  border: 1px solid #80808045;
  border-radius: 5px;
  background-color: #fff;
  padding: 0 3px;
}
.shop-topbar-wrapper .shop-topbar-right .shop-sorting-area label {
  font-size: 0.9rem;
}

.shop-topbar-wrapper
  .shop-topbar-right
  .shop-sorting-area
  .nice-select.nice-select-style-1 {
  /* height: 33px; */
  /* line-height: 32px; */
  border: none;
  background-color: white;
  color: #6d6d6d; 
  border-radius: 4px;
  /* font-style: italic; */
  font-size: 0.9rem;
  /* padding: 3px 11px; */
  /* border-radius: 0;*/
}

.shop-topbar-wrapper
  .shop-topbar-right
  .shop-sorting-area
  .nice-select.nice-select-style-1
  option
  b {
  font-weight: 600 !important;
}

.shop-topbar-wrapper
  .shop-topbar-right
  .shop-sorting-area
  .nice-select.nice-select-style-1::before {
  position: absolute;
  content: "\e64b";
  font-family: "themify";
  right: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
  font-style: normal;
  color: #000000;
}

.shop-topbar-wrapper
  .shop-topbar-right
  .shop-sorting-area
  .nice-select.nice-select-style-1::after {
  display: none;
}

.shop-topbar-wrapper
  .shop-topbar-right
  .shop-sorting-area
  .nice-select.nice-select-style-1.open::before {
  content: "\e648";
}

.shop-topbar-wrapper .shop-topbar-right .shop-view-mode a {
  width: 33px;
  height: 33px;
  line-height: 33px;
  font-size: 18px;
  color: #606060;
  text-align: center;
  border: 1px solid transparent;
  margin-right: 10px;
  background-color: #f3f3f3;
}

.shop-topbar-wrapper .shop-topbar-right .shop-view-mode a.active {
  border: 1px solid #b7b7b7;
  background-color: transparent;
}

.shop-topbar-wrapper .shop-topbar-right .shop-view-mode a:last-child {
  margin-right: 0;
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  -webkit-animation-name: lastudioZoomIn;
  animation-name: lastudioZoomIn;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.product-wrap {
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  border: 1px solid #e0dddd;
}

.product-wrap .product-img {
  overflow: hidden;
  position: relative;
}

.product-wrap .product-img a {
  display: block;
}

.product-wrap .product-img a img {
  width: 100%;
  height: 212px;
  object-fit: fill;
  /* -webkit-transition: all .4s ease 0s;
    -o-transition: all .4s ease 0s;
    transition: all .4s ease 0s; */
}

.product-wrap .product-img .product-badge {
  position: absolute;
  z-index: 5;
  background: #f3d028;
  padding: 5px 25px;
  border-radius: 14px 0;
}
@media (min-width: 1400) {
  .product-wrap .product-img a img {
    width: 100%;
    height: 220px;
    object-fit: fill;
    /* -webkit-transition: all .4s ease 0s;
      -o-transition: all .4s ease 0s;
      transition: all .4s ease 0s; */
  }
}
@media (max-width: 991px) {
  .product-wrap .product-img a img {
    width: 100%;
    height: 150px;
    object-fit: fill;
  }

  .product-wrap .product-img .product-badge {
    position: absolute;
    z-index: 5;
    background: #f3d028;
    padding: 3px 14px;
    border-radius: 14px 0;
  }
}

.product-wrap .product-img .product-badge.badge-top {
  top: -1px;
}

/* .product-wrap .product-img .product-badge.badge-right {
    right: 11px;
  } */

.product-wrap .product-img .product-badge.badge-pink {
  color: #000;
}

.product-wrap .product-img .product-badge span {
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 991px) {
  .product-wrap .product-img .product-badge span {
    font-size: 12px;
    font-weight: 500;
  }
}

.new-badge span {
  position: absolute;
  bottom: 1px;
  right: 1px;
}

.new-badge span img {
  width: 55px;
}

.discount-badge span {
  padding: 3px 15px;
  background: #24bf0b;
  position: absolute;
  bottom: 5px;
  left: 5px;
  /* right: 10px; */
  border-radius: 20px;
  color: #fff;
}

.discount-badge.discount-badge-2 span {
  padding: 3px 15px;
  background: #24bf0b;
  position: absolute;
  bottom: 16px;
  right: 64px;
  border-radius: 20px;
  color: #fff;
}

.product-wrap .product-img .product-badge-2 {
  position: absolute;
  top: 25px;
  right: 30px;
  /* -webkit-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s; */
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.product-wrap .product-img .product-badge-2 span {
  font-size: 16px;
  font-weight: 500;
  color: #fd1748;
}

.product-wrap .product-img .product-action-3-wrap {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0.7);
  -ms-transform: translateY(-50%) scale(0.7);
  transform: translateY(-50%) scale(0.7);
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-img .product-action-3-wrap a {
  display: inline-block;
  font-weight: 500;
  color: #202020;
  border-bottom: 1px solid #9d9998;
  text-transform: uppercase;
  line-height: 1;
}

.product-wrap .product-img .product-action-3-wrap a:hover {
  color: #e97730;
  border-bottom: 1px solid #e97730;
}

.product-wrap .product-img .product-action-wrap {
  display: inline-block;
  left: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1 {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
  font-size: 23px;
  height: 45px;
  margin: 0 3px;
  text-align: center;
  width: 45px;
  border: none;
  border-radius: 6px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1 i {
  line-height: 45px;
}

.product-wrap
  .product-img
  .product-action-wrap
  .product-action-btn-1:first-child {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}

.product-wrap
  .product-img
  .product-action-wrap
  .product-action-btn-1:last-child {
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1:hover {
  color: #ffffff;
  background-color: #e97730;
}

.product-wrap .product-img .product-action-2-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.product-wrap .product-img .product-action-2-wrap .product-action-btn-2 {
  border: none;
  color: #fff;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  background-color: #000000;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 100%;
  padding: 9px 10px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-wrap .product-img .product-action-2-wrap .product-action-btn-2 i {
  font-size: 22px;
  margin-right: 5px;
}

.product-wrap .product-img .product-action-2-wrap:hover .product-action-btn-2 {
  background-color: #e97730;
}

.product-content {
  background-color: #fff;
  padding: 10px;
}

.product-content p {
  line-height: 1;
  font-size: 0.7rem;
  color: #525050;
}

.product-wrap .product-content h3 {
  font-weight: 500 !important;
  margin: 0 0 10px;
  font-size: 14px;
  height: 47px; /* Adjust as needed to fit 1-2 lines */
  overflow: hidden; /* Ensures content doesn't overflow */
  text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
}

.product-wrap .product-content h3 a {
  color: #525050;
  font-weight: 600;
}

.product-wrap .product-content h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-wrap .product-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-wrap .product-content h3 a:hover {
  color: #e97730;
}

.product-wrap .product-content.product-content-position {
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 2;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap.product-overly:before {
  position: absolute;
  content: "";
  left: 40px;
  right: 40px;
  top: 40px;
  bottom: 40px;
  background-color: rgba(245, 245, 245, 0.88);
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.product-wrap:hover .product-img a img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 0.5s;
}

.product-wrap:hover .product-img .product-badge-2 {
  top: 15px;
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-img .product-action-3-wrap {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-50%) scale(1);
  -ms-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}

.product-wrap:hover .product-action-wrap {
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-action-wrap .product-action-btn-1:first-child {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.product-wrap:hover .product-action-wrap .product-action-btn-1:last-child {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.product-wrap:hover .product-action-2-wrap {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover.product-overly:before {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-content.product-content-position {
  bottom: 20px;
  left: 25px;
  opacity: 1;
  visibility: visible;
}

.pagination-style-1 ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pagination-style-1 ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  border-radius: 100%;
  margin: 0 4px;
  color: #333;
  text-align: center;
}

.pagination-style-1 ul li a:hover {
  color: #e97730;
}

.pagination-style-1 ul li a.active {
  background-color: #e97730;
  color: #ffffff;
}

/*-------- Sidebar style ---------*/
/* .sidebar-widget.sidebar-widget-border {
    border-bottom: 1px solid #b7b7b7;
} */

.sidebar-widget .search-wrap-2 .search-2-form {
  position: relative;
}

.sidebar-widget .search-wrap-2 .search-2-form input {
  border: 1px solid #b7b7b7;
  height: 45px;
  color: #6d6d6d;
  font-weight: 300;
  font-style: italic;
  padding: 2px 50px 2px 15px;
}

.sidebar-widget .search-wrap-2 .search-2-form input::-moz-input-placeholder {
  color: #6d6d6d;
  opacity: 1;
}

.sidebar-widget .search-wrap-2 .search-2-form input::-webkit-input-placeholder {
  color: #6d6d6d;
  opacity: 1;
}

.sidebar-widget .search-wrap-2 .search-2-form .button-search {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: #000000;
  padding: 0;
}

.sidebar-widget .sidebar-widget-title h3 {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #343434;
  line-height: 1;
  /* padding-left: 12px; */
}

/* .sidebar-widget .sidebar-widget-title h3:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #e97730;
  } */

.sidebar-widget .sidebar-widget-title-2 h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
  color: #4a4a4a;
  padding-left: 35px;
  position: relative;
}

.sidebar-widget .sidebar-widget-title-2 h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  background-color: #e97730;
}

.sidebar-widget .sidebar-widget-title-2 h3:after {
  position: absolute;
  content: "";
  left: 7px;
  top: 7px;
  width: 16px;
  height: 16px;
  background-color: #e97730;
  border: 2px solid #ffffff;
}

.sidebar-widget .price-filter #slider-range {
  background: rgba(0, 0, 0, 0.1);
  border: medium none;
  border-radius: 0px;
  height: 3px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-top: 5px;
}

.sidebar-widget .price-filter #slider-range span {
  -webkit-transition: all 0s ease 0s;
  -o-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #b7b7b7;
  border: none;
  -webkit-transition: all 0s ease 0s;
  -o-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #ffffff;
  border: medium none;
  border-radius: 50%;
  height: 13px;
  margin-left: 0;
  width: 13px;
  margin-top: -1px;
  border: 2px solid #b7b7b7;
}

.sidebar-widget .price-filter .price-slider-amount {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0 0;
}

.sidebar-widget .price-filter .price-slider-amount .label-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.sidebar-widget .price-filter .price-slider-amount .label-input label {
  font-size: 14px;
  color: #575757;
  font-family: "Poppins", sans-serif;
  margin-right: 2px;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  height: auto;
  width: 50%;
  font-size: 14px;
  background-color: transparent;
  border: none;
  color: #575757;
  font-family: "Poppins", sans-serif;
}



@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .price-filter .price-slider-amount .label-input input {
    width: 55%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .price-filter .price-slider-amount .label-input input {
    width: 65%;
  }
}

.sidebar-widget .price-filter .price-slider-amount button {
  background-color: transparent;
  border: medium none;
  color: #575757;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-family: "Poppins", sans-serif;
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  color: #ed3503;
}

.sidebar-widget .sidebar-list-style ul li {
  display: block;
  margin: 0 0 14px;
}

.sidebar-widget .sidebar-list-style ul li:last-child {
  margin: 0 0 0;
}

.sidebar-widget .sidebar-list-style ul li a {
  color: #575757!important;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sidebar-widget .sidebar-list-style ul li a:hover {
  color: #e97730;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a {
  padding-left: 32px;
  position: relative;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 11px;
  height: 11px;
  border-radius: 100%;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.black:before {
  background-color: #000000;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.blue:before {
  background-color: #0000ff;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.brown:before {
  background-color: #b2aaaa;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.red:before {
  background-color: #ff0000;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.orange:before {
  background-color: #ffba00;
}

.sidebar-widget .sidebar-widget-tag a {
  color: #575757;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 10px;
}

.sidebar-widget .sidebar-widget-tag a:hover {
  color: #e97730;
}

.sidebar-widget .search-wrap-3 .search-3-form {
  position: relative;
}

.sidebar-widget .search-wrap-3 .search-3-form input {
  border: 1px solid #dcdcdc;
  height: 60px;
  color: #a6a6a6;
  font-weight: 300;
  font-style: italic;
  padding: 2px 70px 2px 25px;
}

.sidebar-widget .search-wrap-3 .search-3-form input::-moz-input-placeholder {
  color: #a6a6a6;
  opacity: 1;
}

.sidebar-widget .search-wrap-3 .search-3-form input::-webkit-input-placeholder {
  color: #a6a6a6;
  opacity: 1;
}

.sidebar-widget .search-wrap-3 .search-3-form .button-search {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 19px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: #000000;
  padding: 0;
  color: #818181;
}

.sidebar-widget .search-wrap-3 .search-3-form .button-search:before {
  position: absolute;
  content: "";
  left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 30px;
  background-color: #dcdcdc;
}

.sidebar-widget .search-wrap-3 .search-3-form .button-search:hover {
  color: #e97730;
}

.sidebar-widget .blog-author-content {
  background-color: #f4f4f4;
  padding: 40px 20px 35px;
}

.sidebar-widget .blog-author-content > a {
  display: inline-block;
  max-width: 100%;
}

.sidebar-widget .blog-author-content h2 {
  font-size: 24px;
  font-weight: 500;
  color: #4a4a4a;
  margin: 16px 0 0;
  line-height: 1;
}

.sidebar-widget .blog-author-content h4 {
  font-size: 16px;
  color: #4a4a4a;
  margin: 9px 0 25px;
}

.sidebar-widget .blog-author-content .social-icon-style-1 a {
  font-size: 14px;
  margin: 0 8px;
  display: inline-block;
}

.sidebar-widget .sidebar-list-style-2 ul li {
  display: block;
  margin: 0 0 8px;
}

.sidebar-widget .sidebar-list-style-2 ul li:last-child {
  margin: 0 0 0px;
}

.sidebar-widget .sidebar-list-style-2 ul li a {
  font-size: 16px;
  font-weight: 300;
  color: #2f2f2f;
  display: inline-block;
  font-family: "Poppins", sans-serif;
}

.sidebar-widget .sidebar-list-style-2 ul li a:hover {
  color: #e97730;
}

.sidebar-widget .latest-post-wrap .single-latest-post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 26px;
}

.sidebar-widget .latest-post-wrap .single-latest-post:last-child {
  margin-bottom: 0;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 115px;
  -ms-flex: 0 0 115px;
  flex: 0 0 115px;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 80px;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 110px;
    -ms-flex: 0 0 110px;
    flex: 0 0 110px;
    margin-right: 10px;
  }
  
}
@media (max-width: 600px) {
  .mobile-font-size {
    font-size: 10px !important;
  }
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img a {
  display: inline-block;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img a img {
  width: 100%;
}

.sidebar-widget
  .latest-post-wrap
  .single-latest-post
  .latest-post-content
  span {
  text-transform: uppercase;
  font-weight: 500;
  color: #777777;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-content h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4a4a4a;
  margin: 4px 0 7px;
  line-height: 23px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget
    .latest-post-wrap
    .single-latest-post
    .latest-post-content
    h4 {
    line-height: 20px;
    font-size: 15px;
    margin: 4px 0 6px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget
    .latest-post-wrap
    .single-latest-post
    .latest-post-content
    h4 {
    line-height: 20px;
    font-size: 15px;
    margin: 4px 0 6px;
  }
}

.sidebar-widget
  .latest-post-wrap
  .single-latest-post
  .latest-post-content
  h4
  a {
  color: #4a4a4a;
}

.sidebar-widget
  .latest-post-wrap
  .single-latest-post
  .latest-post-content
  h4
  a:hover {
  color: #e97730;
}

.sidebar-widget
  .latest-post-wrap
  .single-latest-post
  .latest-post-content
  .latest-post-btn
  a {
  font-weight: 500;
  color: #8b8a89;
  display: inline-block;
  text-transform: uppercase;
}

.sidebar-widget
  .latest-post-wrap
  .single-latest-post
  .latest-post-content
  .latest-post-btn
  a:hover {
  color: #e97730;
}

.sidebar-widget .sidebar-widget-tag-2 a {
  font-weight: 500;
  color: #959595;
  display: inline-block;
  line-height: 1;
  border: 1px solid #eeeeee;
  padding: 11px 14px;
  margin: 0 11px 15px 0;
}

.sidebar-widget .sidebar-widget-tag-2 a:hover {
  color: #ffffff;
  background-color: #e97730;
  border: 1px solid #e97730;
}

.sidebar-widget .sidebar-banner {
  overflow: hidden;
}

.sidebar-widget .sidebar-banner a {
  display: block;
}

.sidebar-widget .sidebar-banner a img {
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-banner a img {
    width: inherit;
    max-width: 100%;
  }
}

.sidebar-widget .sidebar-banner:hover a img {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper {
    margin-top: 0px;
  }

  .sidebar-wrapper.blog-sidebar-mt {
    margin-top: 50px;
  }

  .shop-page-responsive.pb-100 {
    padding-bottom: 55px;
  }
}

.product-price span {
  color: #808080;
  font-size: 18px;
  font-weight: 500;
}

.product-price span.new-price {
  color: #555252;
  font-size: 1.3rem;
  font-weight: 800;
  margin-top: 5px;
}

.product-price a i {
  color: #555252;
  font-size: 1rem;
}

.product-price span.old-price {
  margin-right: 13px;
  color: #555252;
  text-decoration: line-through;
}

.product-img #change-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  /* transition: all 0.7s ease; */
}

#change-img:hover {
  opacity: 1;
  border-radius: 0;
}

.sidebar-widget .sidebar-widget-title .check-widget {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.sidebar-widget .sidebar-widget-title .check-widget p {
  color: #000;
  margin: 0;
  padding: 0 0 0 10px;
}

.product-price {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 5px; */
  align-items: center;
}

.price-widget {
  display: flex;
  align-items: center;
}

.price-widget input {
  width: 80px;
  margin: 6px 6px 6px 0;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 0 4px;
}
.price-widget .aply {
  padding: 0px 10px !important;
  background-color: #d4d3d31f;
  border: 1px solid gray;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-list-style .accordion {
  margin-top: 10px;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-list-style .accordion .accordion-item {
  background-color: transparent;
  border: none;
}
.sidebar-list-style
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button {
  background-color: transparent;
  font-size: 16px;
  color: #575757;
  padding: 2px 0px;
}

.sidebar-list-style .accordion .accordion-item .accordion-body {
  padding-left: 0;
  overflow-y: scroll;
}

.sidebar-widget .accordion .check-widget {
  display: flex;
  gap: 10px;  /* between checkbox and label on the right */
  align-items: center;
  margin-left: 0; /* Adjust if needed to align with other sections */
  margin-top: 2px;
  color: #000;
}
.sidebar-widget .accordion .check-widget label {
  cursor: pointer;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.sidebar-widget .accordion .show-more-btn {
  margin-left: 0;
  margin-right: 0;
  color: black;
  border: none;
  padding: 0px 0px 0px 0px;
  cursor: pointer;
  margin-top: 5px; /* Adjust based on your layout */
  width: 100%; /* Optional: To extend the button width to match the accordion body */
  border-radius: 0px; /* Optional: For rounded corners */
  font-size: 14px; /* Optional: Adjust based on your design */
  text-align: left; /* Align text to the left */
  display: block;
  background: none; /* Remove background */
  text-decoration: underline;
}


.brown {
  background: brown;
  padding: 5px;
  border-radius: 50%;
}
.black {
  background: black;
  padding: 5px;
  border-radius: 50%;
}

.white {
  background: white;
  padding: 5px;
  border: 1px solid;
  border-radius: 50%;
}
.gray {
  background: gray;
  padding: 5px;
  border-radius: 50%;
}
.beige {
  background: beige;
  padding: 5px;
  border-radius: 50%;
}
.red {
  background: red;
  padding: 5px;
  border-radius: 50%;
}
.blue {
  background: blue;
  padding: 5px;
  border-radius: 50%;
}
.purple {
  background: purple;
  padding: 5px;
  border-radius: 50%;
}

.brand-search {
  padding: 0 5px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #8080805e;
}
.previous-btn {
  border: 1px solid red;
  border-radius: 20px;
  padding: 5px 8px;
}


.show-filter {
  background: #e97730;
  color: #fff;
  border: 1px solid #e97730;
}
.show-filter:hover {
  background: #ff883e;
}
.fav-btn {
  color: rgb(255, 42, 42);
}

.fav-btn:hover {
  transform: scale(1.2);
  color: red;
}

.pagination-btn {
  width: 100px;
  height: fit-content;
  /* background-color: #e67929; */
  
  
}

.pagination-btn.disabled {
  background-color: #cccccc; /* Disabled background color */
}
.collapse {
  visibility: unset!important;
}
@media (max-width: 768px) {
  .product-wrap {
    position: relative;
  }

  .favorite-heart {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem; /* Adjust the size of the icon if needed */
  }
  .product-price span.new-price{
    font-size: 1rem;
  }
  .align-items-right{
    text-align: right;
  }
}
.favorite-heart {
  font-size: 1.2rem; /* Adjust the size of the icon if needed */
  margin-top: 10px;
}